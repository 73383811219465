/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { Stack } from 'components';

import StepConfigurationSidebar from './StepConfigurationSidebar/StepConfigurationSidebar';
import MissingEnvironmentBlurOverlay from './MissingEnvironmentBlurOverlay';
import ActionsSidebar from './ActionsSidebar/ActionsSidebar';
import { EditorSettingsContext } from './useEditorSettings';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import ExperimentSubHeader from './ExperimentSubHeader';
import DragAndDropHandler from './DragAndDropHandler';
import { ExperimentExecutionVO } from '../../ui-api';
import ExperimentHeader from './ExperimentHeader';
import Workspace from './Workspace/Workspace';
import { ActionsContext } from './useActions';

interface ExperimentEditorProps {
	experimentExecution?: ExperimentExecutionVO;
}

export default function ExperimentEditor({ experimentExecution }: ExperimentEditorProps): ReactElement {
	const actions = usePromise(() => Services.actions.fetchActions(), []);

	return (
		<EditorSettingsContext.Provider value={{ mode: 'experiment' }}>
			<ActionsContext.Provider value={{ actions: actions.value || [] }}>
				<UnsavedChangesPrompt />
				<Stack size="none">
					<ExperimentHeader section="design" experimentExecution={experimentExecution} />
					<ExperimentSubHeader />

					{/* Since items can be dragged from the sidebar to the workspace, the context must be mounted here */}
					<DragAndDropHandler>
						<Stack direction="horizontal" size="none">
							<ActionsSidebar />
							<Workspace mode="experiment" />
						</Stack>
					</DragAndDropHandler>

					<MissingEnvironmentBlurOverlay />

					<StepConfigurationSidebar />
				</Stack>
			</ActionsContext.Provider>
		</EditorSettingsContext.Provider>
	);
}
