/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React, {
	ChangeEvent,
	FocusEvent,
	useCallback,
	useImperativeHandle,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { Container, StyleProp } from 'components';
import { theme } from 'styles.v2/theme';

import { ButtonIcon } from '../ButtonIcon';
import { Text, TextProps } from '../Text';
import { IconComponent } from '../icons';

export interface TextFieldProps extends Omit<TextProps, 'onChange' | 'as'> {
	iconLeft?: IconComponent;
	iconLeftColor?: string;
	iconRight?: IconComponent;
	iconRightColor?: string;
	iconRightTooltip?: string;
	iconRight2?: IconComponent;
	onIconRightClick?: React.MouseEventHandler;
	onIconRight2Click?: React.MouseEventHandler;
	hasError?: boolean;
	prefix?: string;
	value?: string;
	defaultText?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
	as?: 'input' | 'textarea';
	disabled?: boolean;
	readOnly?: boolean;
	wrapperSx?: StyleProp;
	placeholder?: string;
	type?: string;
	autoFocus?: boolean;
	maxLength?: number;
	defaultValue?: string;
	min?: number;
	max?: number;
}

export const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
	(
		{
			onIconRightClick,
			onIconRight2Click,
			iconRight,
			iconRight2,
			iconLeft,
			iconLeftColor,
			disabled,
			placeholder,
			hasError,
			prefix,
			iconRightColor,
			iconRightTooltip,
			mx,
			my,
			mt,
			mb,
			ml,
			mr,
			wrapperSx, // this component is so broken, we need to add another sx prop
			sx,
			as = 'input',
			defaultText,
			variant = 'medium',
			onChange,
			maxLength,
			...props
		},
		ref,
	) => {
		const innerRef = useRef<HTMLInputElement>(null);
		useImperativeHandle<HTMLDivElement | null, HTMLInputElement | null>(ref, () => innerRef.current);

		const [paddingLeft, setPaddingLeft] = useState<string | number>(iconLeft ? '38px' : 'small');
		const prefixRef = useRef<HTMLDivElement | null>(null);

		const IconLeft = iconLeft;
		const IconRight = iconRight;
		const IconRight2 = iconRight2;

		useLayoutEffect(() => {
			if (prefixRef.current && prefix) {
				setPaddingLeft(prefixRef.current.clientWidth);
			}
		}, [prefix]);

		const setValueOnRef = useCallback(
			(value: string): void => {
				const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
				if (valueSetter && innerRef.current) {
					valueSetter.call(innerRef.current, value);
					innerRef.current.dispatchEvent(new Event('input', { bubbles: true }));
				}
			},
			[innerRef],
		);

		let defaultValProps = {};
		if (defaultText !== undefined && defaultText !== '') {
			defaultValProps = {
				onBlur: (e: FocusEvent<HTMLInputElement>) => {
					if (!props.value) {
						setValueOnRef(defaultText);
					}
					props.onBlur?.(e);
				},
				onFocus: (e: FocusEvent<HTMLInputElement>) => {
					if (props.value === defaultText) {
						setValueOnRef('');
					}
					props.onFocus?.(e);
				},
			};
		}

		return (
			<Container
				data-textfield
				width={'100%'}
				tx={'textfield'}
				variant={variant}
				height={as === 'textarea' ? 'auto' : variant}
				my={my}
				mx={mx}
				mt={mt}
				mb={mb}
				ml={ml}
				mr={mr}
				sx={{ ...wrapperSx, display: 'flex', position: 'relative' }}
			>
				{!!(IconLeft || prefix) && (
					<Container
						display={'flex'}
						ref={prefixRef}
						pr="xSmall"
						pl="xxSmall"
						flex="0 0 auto"
						sx={{
							position: 'absolute',
							left: 0,
							top: '50%',
							bottom: 0,
							transform: 'translateY(-50%)',

							pointerEvents: 'none',
						}}
					>
						{!!IconLeft && (
							<IconLeft
								ml="xSmall"
								sx={{
									color: iconLeftColor ?? theme.colors.neutral600,
								}}
							/>
						)}
						{!!prefix && (
							<Text pl="xSmall" color="neutral600">
								{prefix}
							</Text>
						)}
					</Container>
				)}
				<Container
					as={as}
					ref={innerRef}
					flex="1 1 auto"
					pl={paddingLeft}
					pr={IconRight ? (IconRight2 ? 'xLarge' : 'large') : 'small'}
					py="xSmall"
					disabled={disabled}
					maxLength={maxLength}
					color="neutral800"
					data-textfield-input
					sx={{
						borderRadius: 4,
						border: '1px solid',
						borderColor: hasError ? 'coral' : 'neutral300',
						':focus': {
							outline: 'none',
							borderColor: hasError ? 'coral' : 'slate',
							boxShadow: `inset 0 0 0 1px ${hasError ? theme.colors.coral : theme.colors.slate}`,
						},
						':disabled': {
							bg: 'neutral100',
							borderColor: hasError ? 'coral' : 'neutral400',
							color: 'neutral600',
						},
						textOverflow: 'ellipsis',
						...sx,
					}}
					onChange={(e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
						onChange?.(e);
					}}
					{...props}
					{...defaultValProps}
					placeholder={disabled ? '' : placeholder}
				/>
				{!!IconRight && (
					<ButtonIcon
						onClick={onIconRightClick}
						tooltip={iconRightTooltip}
						muted={disabled}
						disabled={disabled}
						tabIndex={-1}
						data-textfield-icon-right
						sx={{
							position: 'absolute',
							right: 2,
							top: '50%',
							bottom: 0,
							transform: 'translateY(-50%)',
						}}
					>
						<IconRight color={iconRightColor} />
					</ButtonIcon>
				)}
				{!!IconRight2 && (
					<ButtonIcon
						onClick={onIconRight2Click}
						muted={disabled}
						disabled={disabled}
						tabIndex={-1}
						data-textfield-icon-right
						sx={{
							position: 'absolute',
							right: 24,
							top: '50%',
							bottom: 0,
							transform: 'translateY(-50%)',
						}}
					>
						<IconRight2 />
					</ButtonIcon>
				)}
			</Container>
		);
	},
);
TextField.displayName = 'TextField';
