/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Tag as ExperimentTag, Tags as ExperimentTags } from 'pages/experimentsV2/ExperimentTags';
import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import TemplateTags, { Tag as TemplateTag } from 'pages/templates/components/TemplateTags';
import { ActionVO, SearchObjectsVO, TargetTypeDescriptionVO } from 'ui-api';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { usePromise } from 'utils/hooks/usePromise';
import { Target } from 'pages/components/Target';
import { useUrlState } from 'url/useUrlState';
import Action from 'pages/components/Action';
import { Services } from 'services/services';
import { Stack, Text } from 'components';

import DropdownSearchInput from './DropdownSearchInput';
import SearchObjects from './SearchObjects';
import Environment from '../Environment';
import FreeText from '../FreeText';

interface SearchBarProps {
	targetDefinitionsResult: DataStreamResult<TargetTypeDescriptionVO[]>;
	mode: 'experimentList' | 'templatePreviews' | 'templateList';
	searchObjectsResult: DataStreamResult<SearchObjectsVO>;
	actionsResult: DataStreamResult<ActionVO[]>;
	cypressTag?: string;
	pathname: string;
}

export default function SearchBar({
	targetDefinitionsResult,
	searchObjectsResult,
	actionsResult,
	cypressTag,
	pathname,
	mode,
}: SearchBarProps): ReactElement {
	const [actionsData] = useAsyncState(async () => {
		return await Promise.all(
			(actionsResult.value || []).map(async (action) => {
				action.name = (await Services.actions.findActionNameWithTargetTypeIfNotUnique(action.id)) ?? action.name;
				return action;
			}),
		);
	}, [actionsResult.value]);
	const actions = actionsData.value || [];

	const environmentsResult = usePromise(async () => (await Services.environments.fetchEnvironments()).content, []);
	const environemntIdToName = useMemo(() => {
		const map = new Map<string, string>();
		if (environmentsResult.value) {
			environmentsResult.value.forEach((env) => {
				map.set(env.id, env.name);
			});
		}
		return map;
	}, [environmentsResult.value]);

	const [{ environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, pageParam }] = useState(
		() => createFilterParams(pathname),
	);

	const [
		{
			environmentIds: environmentIdsFromUrl,
			targetTypes: targetTypesFromUrl,
			actions: actionsFromUrl,
			tags: tagsFromUrl,
			freeTextPhrases,
		},
		,
		updateUrlState,
	] = useUrlState<UrlState>([
		tagsParam,
		environmentIdParam,
		targetTypesParam,
		actionsParam,
		freeTextPhrasesParam,
		pageParam,
	]);

	const commonTags = searchObjectsResult.value?.mostUsedTags || [];

	return (
		<Stack
			size="xSmall"
			sx={{
				p: 'small',
				backgroundColor: 'neutral100',
				borderRadius: '8px',
			}}
		>
			{mode !== 'templateList' && <Text variant="mediumStrong">Search via keywords</Text>}

			<DropdownSearchInput
				renderDropdownContent={({ value, setValue, width }) => {
					value = value.trim();

					return (
						<>
							<KeydownReturnListener
								onDelete={() => {
									if (value !== '') {
										return;
									}
									if (freeTextPhrases.length > 0) {
										updateUrlState({
											page: 0,
											freeTextPhrases: freeTextPhrases.slice(0, freeTextPhrases.length - 1),
										});
									} else if (actionsFromUrl.length > 0) {
										updateUrlState({ page: 0, actions: actionsFromUrl.slice(0, actionsFromUrl.length - 1) });
									} else if (targetTypesFromUrl.length > 0) {
										updateUrlState({
											page: 0,
											targetTypes: targetTypesFromUrl.slice(0, targetTypesFromUrl.length - 1),
										});
									} else if (environmentIdsFromUrl.length > 0) {
										updateUrlState({
											page: 0,
											environmentIds: environmentIdsFromUrl.slice(0, environmentIdsFromUrl.length - 1),
										});
									} else if (tagsFromUrl.length > 0) {
										updateUrlState({ page: 0, tags: tagsFromUrl.slice(0, tagsFromUrl.length - 1) });
									}
								}}
							/>
							<SearchObjects
								targetDefinitions={targetDefinitionsResult.value || []}
								searchObjectsResult={searchObjectsResult}
								environemntIdToName={environemntIdToName}
								clearQuery={() => setValue('')}
								actionsData={actions}
								pathname={pathname}
								query={value}
								width={width}
								mode={mode}
							/>
						</>
					);
				}}
				cypressTag={cypressTag}
			>
				{tagsFromUrl.map((tag) => {
					const TagComponent = mode === 'experimentList' ? ExperimentTag : TemplateTag;
					return (
						<TagComponent
							key={tag}
							onDelete={() => updateUrlState({ page: 0, tags: tagsFromUrl.filter((t) => t !== tag) })}
						>
							{tag}
						</TagComponent>
					);
				})}
				{environmentIdsFromUrl.map((id) => {
					return (
						<Environment
							key={id}
							onDelete={() =>
								updateUrlState({
									page: 0,
									environmentIds: environmentIdsFromUrl.filter((t) => t !== id),
								})
							}
						>
							{environemntIdToName.get(id) || ''}
						</Environment>
					);
				})}
				{targetTypesFromUrl.map((target) => (
					<Target
						key={target}
						targetId={target}
						targetDefinitions={targetDefinitionsResult.value || []}
						onDelete={() =>
							updateUrlState({
								page: 0,
								targetTypes: targetTypesFromUrl.filter((t) => t !== target),
							})
						}
					/>
				))}
				{actionsFromUrl.map((action) => (
					<Action
						key={action}
						actionsData={actions}
						action={action}
						onDelete={() => updateUrlState({ page: 0, actions: actionsFromUrl.filter((a) => a !== action) })}
					/>
				))}
				{freeTextPhrases.map((phrase) => (
					<FreeText
						key={phrase}
						onDelete={() =>
							updateUrlState({
								page: 0,
								freeTextPhrases: freeTextPhrases.filter((p) => p !== phrase),
							})
						}
					>
						{phrase}
					</FreeText>
				))}
			</DropdownSearchInput>

			{mode !== 'templateList' && (
				<MostCommonTags
					mode={mode}
					tags={commonTags}
					onTagClick={(tag) => {
						if (!tagsFromUrl.includes(tag)) {
							updateUrlState({ page: 0, tags: [...tagsFromUrl, tag] });
						}
					}}
				/>
			)}
		</Stack>
	);
}

interface MostCommonTagsProps {
	mode: 'experimentList' | 'templatePreviews';
	tags: string[];
	onTagClick: (tag: string) => void;
}

function MostCommonTags({ mode, tags, onTagClick }: MostCommonTagsProps): ReactElement | null {
	if (tags.length === 0) {
		return null;
	}

	return (
		<Stack direction="horizontal" size="xSmall">
			<Text variant="smallStrong" color="neutral600" mt="3px" minWidth="128px">
				Most common tags:
			</Text>
			{mode === 'experimentList' ? (
				<ExperimentTags tags={tags} onClick={onTagClick} />
			) : (
				<TemplateTags tags={tags} onClick={onTagClick} />
			)}
		</Stack>
	);
}

function KeydownReturnListener({ onDelete }: { onDelete: () => void }): null {
	const keyDownListener = (event: KeyboardEvent): void => {
		if (event.key === 'Backspace') {
			onDelete();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keyDownListener, true);
		return () => window.removeEventListener('keydown', keyDownListener, true);
	}, [keyDownListener]);

	return null;
}
