/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	isPredicateEditableWithTheQueryBuilder,
	isQueryLanguagePredicateVO,
	isTargetAttributeKeyValuePredicateVO,
} from 'components/PredicateEditor/utils';
import VariablesAndPlaceholders from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/VariablesAndPlaceholders';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { KeyError, ValuesError } from 'components/PredicateEditor/PredicateEditor';
import { EnvironmentVariableVO, QueryLanguagePredicateVO } from 'ui-api';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DropdownInput from 'components/Select/Dropdown/DropdownInput';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { ExperimentError } from 'pages/experimentsV2/types';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { getCategoryLabel } from 'services/targetsApi';
import { Colors } from '@steadybit/ui-components-lib';
import { quoteForVariables } from 'utils/envVars';
import { groupBy, isEmpty } from 'lodash';

import { ButtonIcon, Container, smellsLikeTemplatePlaceholder, Stack, Tag, TextField, Tooltip } from '..';
import { OptionTypeBase, SelectOptionGroup } from '../Select/SelectOptionTypes';
import { IconDelete, IconRoundSpinner, IconWarningCircle } from '../icons';
import DropdownInputFilterable from './DropdownInputFilterable';
import * as Types from '../Select/SelectOptionTypes';
import { AndDivider, OrDivider } from './Divider';
import { find } from '../Select/utils';
import { Divider } from '../Divider';
import { Select } from '../Select';

interface CompositePredicate {
	operator?: string;
	predicates?: AttributePredicate[];
}

interface AttributePredicate {
	key: string;
	operator: string;
	values?: string[];
}

export type ValueValidator = (
	value: string,
	variables: EnvironmentVariableVO[],
	options: Types.SelectOptions<OptionTypeBase>[],
) => [boolean, ExperimentError | undefined];

interface PredicateBuilderProps {
	partErrors?: Array<KeyError | ValuesError>;
	variables?: EnvironmentVariableVO[];
	attributeKeysLoading: boolean;
	value?: CompositePredicate;
	attributeKeys?: string[];
	operators?: string[];
	disabled?: boolean;
	onAttributeKeyChange?: (key: string, change: 'added' | 'removed' | 'replaced') => void;
	onChange?: (value: CompositePredicate | undefined) => void;
	fetchAttributeValues?: (key: string) => Promise<string[]>;
	valueValidator?: ValueValidator;
}

const emptyPredicate: CompositePredicate = { predicates: [], operator: 'AND' };
const operators = ['EQUALS', 'CONTAINS', 'EQUALS_IGNORE_CASE', 'CONTAINS_IGNORE_CASE'];

export default function PredicateBuilder({
	value = emptyPredicate,
	attributeKeysLoading,
	partErrors = [],
	variables = [],
	attributeKeys,
	disabled,
	onAttributeKeyChange,
	fetchAttributeValues,
	valueValidator,
	onChange,
}: PredicateBuilderProps): ReactElement {
	value = correctPredicate(value);

	const [newPredicate, setNewPredicate] = useState<AttributePredicate>({
		key: '',
		operator: 'EQUALS',
		values: [],
	});
	const predicates = value.predicates || [];
	const predicateList = [...predicates, ...(disabled ? [] : [newPredicate])];

	if (disabled && predicateList.length === 0) {
		return (
			<Tag variant="small" bg="cyanLight" m={4}>
				any
			</Tag>
		);
	}

	return (
		<Stack size="xSmall">
			{predicateList.map((v, i) => {
				const isNewPredicate = i >= (value.predicates?.length ?? 0);
				const handlers = isNewPredicate
					? {
							onChange: setNewPredicate,
							onDelete: undefined,
							onComplete: (predicate: AttributePredicate) => {
								onAttributeKeyChange?.(predicate.key, 'added');
								onChange?.({ ...value, predicates: [...predicates, predicate] });
								setNewPredicate({
									key: '',
									operator: 'EQUALS',
									values: [],
								});
							},
						}
					: {
							onChange: (predicate: AttributePredicate) => {
								onAttributeKeyChange?.(predicate.key, 'replaced');
								onChange?.({
									...value,
									predicates: replaceAt(predicates, i, predicate),
								});
							},
							onDelete: () => {
								onAttributeKeyChange?.(predicates[i].key, 'removed');
								const newPredicates = removeAt(predicates, i);
								if (newPredicates.length) {
									return onChange?.({
										...value,
										predicates: newPredicates,
									});
								} else {
									return onChange?.(undefined);
								}
							},
							onComplete: undefined,
						};

				const error: KeyError | ValuesError | undefined = partErrors[i];

				return (
					<React.Fragment key={i}>
						{i > 0 && <AndDivider />}
						<SinglePredicateBuilder
							attributeKeysLoading={attributeKeysLoading}
							attributeKeys={attributeKeys}
							autoFocus={!v.key && i === 0}
							operators={operators}
							id={`predicate-${i}`}
							variables={variables}
							disabled={disabled}
							error={error}
							value={v}
							fetchAttributeValues={fetchAttributeValues}
							valueValidator={valueValidator}
							{...handlers}
						/>
					</React.Fragment>
				);
			})}
		</Stack>
	);
}

function replaceAt<T>(array: T[], index: number, item: T): T[] {
	const newArray = [...array];
	newArray[index] = item;
	return newArray;
}

function removeAt<T>(array: T[], index: number): T[] {
	const newArray = [...array];
	newArray.splice(index, 1);
	return newArray;
}

function getCategory(key: string): string {
	if (key.startsWith('k8s.pod.label')) {
		return 'k8s.pod.label';
	}
	const [category] = key.split('.', 1);
	return category ?? key;
}

type AttributeKeyOption = OptionTypeBase;
type AttributeKeyGroup = SelectOptionGroup<AttributeKeyOption>;
type AttributeValueOption = OptionTypeBase;

function operatorsToOptions(operators: string[]): OptionTypeBase[] {
	return operators.map((operator) => ({
		label: operator.toLowerCase().replace(/_/g, ' '),
		value: operator,
	}));
}

function attributesKeyToOptions(attributeKeys: string[]): AttributeKeyGroup[] {
	const byCategory = Object.entries(groupBy(attributeKeys, getCategory));
	return byCategory.map(([category, keys]) => ({
		label: getCategoryLabel(category),
		options: keys.map((key) => ({
			label: key,
			value: key,
		})),
	}));
}

function attributesToValueOptions(values: string[]): AttributeValueOption[] {
	return values.map((value) => {
		const quoted = quoteForVariables(value);
		return { label: quoted, value: quoted };
	});
}

interface SinglePredicateBuilderProps {
	error: KeyError | ValuesError | undefined;
	variables: EnvironmentVariableVO[];
	attributeKeysLoading: boolean;
	value: AttributePredicate;
	attributeKeys?: string[];
	autoFocus?: boolean;
	operators?: string[];
	disabled?: boolean;
	id?: string;
	fetchAttributeValues?: (key: string) => Promise<string[]>;
	onComplete?: (value: AttributePredicate) => void;
	onChange: (value: AttributePredicate) => void;
	valueValidator?: ValueValidator;
	onDelete?: () => void;
}

const SinglePredicateBuilder: React.VFC<SinglePredicateBuilderProps> = ({
	attributeKeys = [],
	attributeKeysLoading,
	id = 'predicate',
	operators = [],
	autoFocus,
	variables,
	disabled,
	error,
	value,
	fetchAttributeValues = () => [],
	valueValidator,
	onComplete,
	onChange,
	onDelete,
}) => {
	const operatorOptions = useMemo(() => operatorsToOptions(operators), [operators]);
	const keyOptions = useMemo(() => attributesKeyToOptions(attributeKeys), [attributeKeys]);
	const selectedKey = useMemo(
		() => find(keyOptions, value.key ? (o) => o.value === value.key : undefined) ?? null,
		[keyOptions, value.key],
	);

	const [valueOptions] = useAsyncState(
		async () => (value.key ? attributesToValueOptions(await fetchAttributeValues(value.key)) : []),
		[fetchAttributeValues, value.key],
	);

	const filledValues = value.values?.filter(Boolean) ?? [];
	const values = React.useMemo(() => {
		const allSelectionsAreValid = filledValues?.length === 0 || filledValues.filter((v) => v != null).length > 0;
		return disabled || !allSelectionsAreValid
			? filledValues
			: [...filledValues, { key: '', operator: 'EQUALS', values: [] }];
	}, [valueOptions.value, filledValues.join(','), disabled]);

	const emitChange = (predicate: AttributePredicate): void => {
		onChange(predicate);
		onComplete?.(predicate);
	};

	const validationError: ExperimentError | undefined =
		!smellsLikeTemplatePlaceholder(value.key) && error && 'key' in error ? error.key : undefined;
	const hasError: boolean = !!validationError;

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr auto 1fr auto',
				gap: '8px',
				alignItems: 'center',
			}}
		>
			<DropdownInputFilterable
				iconRight={validationError && <ErrorIndicator error={validationError} />}
				attributeKeys={attributeKeys}
				attributeKeysLoading={attributeKeysLoading}
				autoFocus={autoFocus}
				disabled={!!disabled}
				hasError={hasError}
				value={value.key}
				small
				onValueChanged={(_v) => {
					emitChange({ ...value, key: _v, values: [] });
				}}
				onItemSelected={() => {
					const firstValueInput = document.getElementById(`${id}-value-0-input`);
					if (firstValueInput) {
						firstValueInput.focus();
					}
				}}
			/>
			<Select
				id={`${id}-operator`}
				variant="small"
				options={operatorOptions}
				value={operatorOptions.find((option) => option.value === value.operator)}
				width="120px"
				onChange={(option) => emitChange({ ...value, operator: option?.value || '', values: [] })}
				disabled={disabled || (!value.key && isEmpty(value.values))}
			/>
			{values.map((_, i) => {
				const hasValue = value.values?.[i] !== undefined;
				let canDelete: boolean = false;
				if (i > 0) {
					canDelete = hasValue;
				} else if (i === 0) {
					canDelete = hasValue || Boolean(value.key) || value.operator !== 'EQUALS';
				}

				return (
					<Fragment key={i}>
						<ValueInput
							id={`${id}-value-${i}`}
							options={valueOptions?.value ?? []}
							value={value.values?.[i] ?? ''}
							loading={valueOptions.loading}
							operator={value.operator}
							disabled={disabled}
							validateValue={(_value, _variables, _options) => {
								const externalValidationResult: [boolean, ExperimentError | undefined] = valueValidator
									? valueValidator(_value, variables, _options)
									: [true, undefined];

								if (!externalValidationResult[0]) {
									return externalValidationResult;
								}

								if (error && 'values' in error && error.values[i]) {
									return [false, error.values[i]];
								}

								return [true, undefined];
							}}
							isFirst={i === 0}
							variables={variables}
							onChange={(changed) => {
								const values = replaceAt(value.values ?? [], i, changed ?? '');
								if (values.lastIndexOf('') === values.length - 1) {
									values.pop();
								}

								emitChange({
									...value,
									values,
								});
							}}
						/>
						<ButtonIcon
							id={`${id}-delete`}
							variant="small"
							color="neutral600"
							tooltip="Delete"
							disabled={!canDelete || disabled || !onDelete}
							onClick={() => {
								const values = value.values ?? [];
								if (values.length <= 1) {
									onDelete?.();
									return;
								}

								const isNewElement = i >= (values.length ?? 0);
								if (isNewElement) {
									emitChange({ ...value, values: removeAt(values, i) });
								} else if (!isNewElement) {
									if (values.length === 1) {
										onDelete?.();
									} else {
										emitChange({ ...value, values: removeAt(values, i) });
									}
								} else if (selectedKey && values.length === 0) {
									emitChange({ ...value, key: '', operator: 'EQUALS' });
								} else {
									onDelete?.();
								}
							}}
						>
							<IconDelete />
						</ButtonIcon>
					</Fragment>
				);
			})}
		</Container>
	);
};

type ValueInputProps = {
	options: Types.SelectOptions<Types.OptionTypeBase>[];
	variables: EnvironmentVariableVO[];
	disabled?: boolean;
	loading?: boolean;
	isFirst: boolean;
	operator: string;
	value?: string;
	id: string;
	onChange: (value: string) => void;
	validateValue: ValueValidator;
};

const ValueInput: React.VFC<ValueInputProps> = ({
	variables,
	operator,
	isFirst,
	options,
	loading,
	value,
	id,
	validateValue,
	onChange,
	...props
}) => {
	const [temporaryValue, setTemporaryValue] = useState(value || '');
	useEffect(() => {
		setTemporaryValue(value || '');
	}, [value]);

	const [hasValidValue, validationError] = validateValue(value || '', variables, options);

	return (
		<>
			{!isFirst && (
				<>
					<div />
					<OrDivider />
				</>
			)}
			{operator === 'EQUALS' || operator === 'EQUALS_IGNORE_CASE' ? (
				<>
					<DropdownInput
						id={`${id}-input`}
						iconRight={validationError && <ErrorIndicator error={validationError} />}
						hasError={!hasValidValue}
						disabled={props.disabled}
						value={temporaryValue}
						placement="bottom-end"
						placeholder="Value"
						width="100%"
						small
						onValueChanged={(_v) => setTemporaryValue(_v)}
						onClose={() => {
							onChange(temporaryValue);
						}}
					>
						{({ selectItem, width }) =>
							loading ? (
								<DropdownContentFrame maxHeight={200}>
									<Container display="flex" alignItems="center" justifyContent="center" height={50} width={width}>
										<IconRoundSpinner variant="large" />
									</Container>
								</DropdownContentFrame>
							) : (
								<DropdownContentFrame maxWidth={380}>
									<Labels
										type="strict"
										onSelect={(selection) => {
											if (selection) {
												selectItem(selection.label);
												onChange(selection.label);
											}
										}}
										labels={options.map((o) => ({
											id: '',
											label: o.label,
										}))}
										maxHeight={400}
										queryString={temporaryValue ?? ''}
										additionalEntry={
											<Container display={'flex'} flexDirection={'column'} backgroundColor="neutral000">
												<Divider />
												<VariablesAndPlaceholders
													width={340}
													selectItem={(_v) => {
														selectItem(_v);
														onChange(_v);
													}}
												/>
											</Container>
										}
									/>
								</DropdownContentFrame>
							)
						}
					</DropdownInput>
				</>
			) : (
				<TextField
					value={temporaryValue}
					placeholder="Value"
					variant="small"
					onChange={(e) => setTemporaryValue(e.target.value)}
					onBlur={() => onChange(temporaryValue)}
					{...props}
				/>
			)}
		</>
	);
};

function correctPredicate(value: CompositePredicate): CompositePredicate {
	if (value && isQueryLanguagePredicateVO(value) && isPredicateEditableWithTheQueryBuilder(value)) {
		const predicate = toTargetPredicate((value as QueryLanguagePredicateVO).query);
		if (isTargetAttributeKeyValuePredicateVO(predicate)) {
			return {
				predicates: [predicate],
				operator: 'AND',
			};
		} else {
			return predicate as CompositePredicate;
		}
	}
	if (value && isTargetAttributeKeyValuePredicateVO(value)) {
		return {
			predicates: [value],
			operator: 'AND',
		};
	}
	return value;
}

function ErrorIndicator({ error }: { error: ExperimentError }): ReactElement {
	return (
		<Tooltip content={error.message}>
			<div
				tabIndex={-1}
				data-textfield-icon-right
				style={{
					padding: '0 4px',
					backgroundColor: 'white',
					color: Colors.coral,
				}}
			>
				<IconWarningCircle color="coral" />
			</div>
		</Tooltip>
	);
}
