/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import DropdownInput from 'components/Select/Dropdown/DropdownInput';
import { FormCheckboxGroup, Stack } from 'components';
import { ReactElement } from 'react';
import { OptionVO } from 'ui-api';

interface MultiSelectProps {
	options: OptionVO[];
	disabled: boolean;
	value: string[];
	setValue: (value: string[]) => void;
}

export default function MultiSelect({ value = [], disabled, options, setValue }: MultiSelectProps): ReactElement {
	const selectedOptions = value.map(
		(v: string) => options.find((o) => o.value === v) ?? { value: v, label: v, hasError: true },
	);

	return (
		<DropdownInput value={selectedOptions.map((e) => e.label).join(', ')} disabled={disabled} width="100%">
			{({ width }) => (
				<DropdownContentFrame>
					<Stack size={'xSmall'} p="small" width={width}>
						<FormCheckboxGroup
							value={selectedOptions}
							onChange={(v) => {
								setValue(v.map((o) => o.value));
							}}
							options={options}
						/>
					</Stack>
				</DropdownContentFrame>
			)}
		</DropdownInput>
	);
}
