/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, RouterButton, Snackbar, Spinner, Stack, Text, Toggle, userConfirmV2 } from 'components';
import { IconCalendar, IconDelete, IconRefresh } from 'components/icons';
import { ReactElement } from 'react';

import { useEventEffect } from '../../../../utils/hooks/useEventEffect';
import { useAsyncState } from '../../../../utils/hooks/useAsyncState';
import { useTenant } from '../../../../tenancy/useTenant';
import { Services } from '../../../../services/services';
import { Separator } from '../../../../hocs/Separator';

async function toggleIcalAccess(checked: boolean): Promise<void> {
	if (checked) {
		try {
			await Services.settingsApi.setIcalAccessEnabled(checked);
		} catch {
			Snackbar.error('Failed to toggle support access');
		}
	} else {
		await userConfirmV2({
			title: 'Disable iCal Access',
			message:
				'Do you really want to disable iCal Access? Existing integration will no longer be able to access the data. Disabling and enabling the access will change the URL. Make sure to update your calendar integrations.',
			actions: [
				{
					value: 'confirm',
					label: 'Disable',
					icon: <IconDelete mr="xSmall" />,
					variant: 'primary',
					action: async () => {
						await Services.settingsApi.setIcalAccessEnabled(checked);
					},
				},
			],
			secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
			width: '700px',
		});
	}
}

async function rotateIcalAccessUrl(): Promise<void> {
	await userConfirmV2({
		title: 'Rotate iCal Access URL',
		message:
			'Do you really want to rotate the iCal Access URL? All existing integration will no longer be able to access the data using the old URL. Make sure to update your calendar integrations.',
		actions: [
			{
				value: 'confirm',
				label: 'Rotate URL',
				icon: <IconRefresh mr="xSmall" />,
				variant: 'primary',
				action: async () => {
					await Services.settingsApi.setIcalAccessEnabled(false);
					await Services.settingsApi.setIcalAccessEnabled(true);
				},
			},
		],
		secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
		width: '700px',
	});
}

export default function IcalSettings(): ReactElement {
	const tenant = useTenant();
	const isAdmin = tenant.user?.role === 'ADMIN';
	const [icalAccessUrl, reloadIcalAccessUrl] = useAsyncState(() => Services.settingsApi.getIcalAccessUrl());

	useEventEffect(reloadIcalAccessUrl, ['tenant.settings.updated'], () => {}, [reloadIcalAccessUrl]);

	return (
		<>
			<Stack minHeight={'100%'} size={'large'} m={'xLarge'}>
				<Stack
					size="small"
					sx={{
						justifyContent: 'space-between',
						border: '1px solid',
						borderColor: 'neutral300',
						borderRadius: '8px',
						p: 'small',
					}}
				>
					<Stack size="small">
						<Stack direction="horizontal" size="xSmall" alignItems="center">
							<IconCalendar variant="large" color="slate" />
							<Text variant="mediumStrong">iCal Access</Text>
						</Stack>
						<Text variant="medium" color="neutral600">
							Allow access to your experiment executions and/or experiment schedules via iCal. This will create a unique
							URL providing a read-only iCal feed which you can use to sync external calendars. Once you activated that
							feature, you can use the link generator to configure your feed.
						</Text>
						<Text variant="smallStrong" color="neutral800">
							Enable iCal Access
						</Text>
						{icalAccessUrl.loading ? (
							<Spinner variant="medium" color="neutral300" alignSelf={'flex-start'} />
						) : (
							<Toggle
								disabled={!isAdmin}
								checked={!!icalAccessUrl.value?.length}
								onChange={(e) => toggleIcalAccess(e.target.checked)}
							/>
						)}
						{icalAccessUrl.value && (
							<>
								<Separator />
								<Text variant="smallStrong" color="neutral800">
									Default iCal Access URL (use the Link Generator to configure the content of the iCal feed)
								</Text>
								<Text variant="medium" color="neutral600" sx={{ lineBreak: 'anywhere' }}>
									{icalAccessUrl.value}?tenantKey={tenant.key}&includeExecutions=true&includeSchedules=true
								</Text>
								<Stack direction="horizontal" alignItems="center" justifyContent="flex-end">
									<Button
										variant={'secondary'}
										width={200}
										disabled={!isAdmin}
										onClick={async () => await rotateIcalAccessUrl()}
									>
										Rotate URL
									</Button>
									<RouterButton variant={'primary'} width={200} to={'/settings/integrations/icalAccess/generate'}>
										Link Generator
									</RouterButton>
								</Stack>
							</>
						)}
					</Stack>
				</Stack>
			</Stack>
		</>
	);
}
