/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components/Container';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, ReactNode } from 'react';
import { Tooltip } from 'components/Tooltip';
import { theme } from 'styles.v2/theme';

import { GeneralDropdownProps, renderChildren } from './types';
import Dropdown from './Dropdown';

interface DropdownInputProps<T> extends Omit<ContainerProps, 'children'>, GeneralDropdownProps<T> {
	iconRight?: ReactNode | ((showMenu: boolean) => ReactNode);
	children: renderChildren<T>;
	popupUpdateSignal?: boolean;
	iconLeft?: ReactNode;
	placeholder?: string;
	value: string;
}

export default function DropdownInput(props: DropdownInputProps<string>): ReactElement {
	const { id, small, hasError, placeholder, disabled, width, iconRight, autoFocus, sx } = props;

	const focusStyle = {
		outline: 'none',
		borderColor: hasError ? 'coral' : 'slate',
		boxShadow: `inset 0 0 0 1px ${hasError ? theme.colors.coral : theme.colors.slate}`,
	};

	return (
		<Dropdown<string>
			{...props}
			renderComponent={({ showMenu, setShowMenu, value, ref, onValueChanged }) => {
				return (
					<Container
						sx={{
							position: 'relative',
							display: 'flex',
						}}
						width={width}
						ref={ref}
					>
						<Tooltip
							content={value}
							placement="top-end"
							bindWidth={{
								target: 'reference',
								offset: 140,
							}}
						>
							<Container
								id={id}
								as="input"
								px="xSmall"
								py={small ? 8 : 11}
								autoComplete="off"
								color="neutral800"
								placeholder={placeholder}
								width={width}
								sx={{
									borderRadius: 4,
									border: '1px solid',
									borderColor: hasError ? 'coral' : 'neutral300',
									boxShadow: hasError ? `inset 0 0 0 1px ${theme.colors.coral}` : undefined,
									':focus': focusStyle,
									...(showMenu ? focusStyle : {}),
									...sx,
									...textEllipsis,
								}}
								onChange={(e) => onValueChanged?.(e.target.value)}
								onFocus={() => {
									setShowMenu(true);
								}}
								onKeyDown={(e) => {
									if (e.key === 'Tab') {
										setShowMenu(false);
									}
								}}
								autoFocus={autoFocus}
								disabled={disabled}
								value={value}
							/>
						</Tooltip>
						{iconRight && (
							<Container
								sx={{
									position: 'absolute',
									right: 4,
									top: 4,
								}}
							>
								{typeof iconRight === 'function' ? iconRight(showMenu) : iconRight}
							</Container>
						)}
					</Container>
				);
			}}
		/>
	);
}
