/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	DragDropContext,
	DraggableStateSnapshot,
	DraggingStyle,
	DropResult,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import { ActionVO, BaseExperimentStepVOUnion, ExperimentLaneVO } from 'ui-api';
import { ReactElement, ReactNode } from 'react';
import { useUrlState } from 'url/useUrlState';
import { useField } from 'formik';

import { instantiateAction, instantiateWait } from './actionHelper';
import { UrlState, selectedStepIdParam } from './urlParams';
import { filterEmpty } from './utils';
import useActions from './useActions';

interface DragAndDropHandlerProps {
	children: ReactNode | ReactNode[];
}

export const WorkspaceLaneFalloffCatcherMaker = 'workspace-lane-falloff-catcher-';
export const WorkspaceLanesMaker = 'workspace-lanes';
export const WorkspaceLaneMaker = 'workspace-lane-';
export const WorkspaceStepMaker = 'workspace-step-';
export const SidebarActionMaker = 'sidebar-action-';
export const SidebarLaneMaker = 'sidebar-actions';

export default function DragAndDropHandler({ children }: DragAndDropHandlerProps): ReactElement {
	const [lanesField, , { setValue: setLanes, setTouched }] = useField<ExperimentLaneVO[]>({ name: 'lanes' });
	const [, , updateUrlState] = useUrlState<UrlState>([selectedStepIdParam]);
	const { value: lanes } = lanesField;
	const availableActions = useActions();

	async function onDragEnd(result: DropResult): Promise<void> {
		if (!result.destination) {
			return;
		}

		// switching lanes
		if (result.draggableId.startsWith(WorkspaceLaneMaker) && result.destination.droppableId === WorkspaceLanesMaker) {
			const newLanes = moveLane(lanes, result.source.index, result.destination.index);
			setLanes(assignIds(newLanes));
			setTouched(true);
			return;
		}

		// dragging from sidebar to workspace
		if (
			result.draggableId.startsWith(SidebarActionMaker) &&
			result.destination.droppableId.startsWith(WorkspaceLaneMaker)
		) {
			const newLanes = await createStep(lanes, result, availableActions, (selectedStepId) => {
				updateUrlState({ selectedStepId });
			});
			if (newLanes) {
				setLanes(assignIds(filterEmpty(newLanes)));
				setTouched(true);
			}
			return;
		}

		// dragging inside workspace
		if (
			result.draggableId.startsWith(WorkspaceStepMaker) &&
			result.destination.droppableId.startsWith(WorkspaceLaneMaker)
		) {
			const newLanes = swap(lanes, result);
			if (newLanes) {
				setLanes(assignIds(filterEmpty(newLanes)));
				setTouched(true);
			}
			return;
		}
	}

	return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
}

async function createStep(
	lanes: ExperimentLaneVO[],
	result: DropResult,
	availableActions: ActionVO[],
	setStepId: (id: string) => void,
): Promise<ExperimentLaneVO[] | null> {
	if (!result.destination) {
		return null;
	}
	const destinationDropId = result.destination.droppableId.substring(WorkspaceLaneMaker.length);
	const actionId = result.draggableId.substring(SidebarActionMaker.length);

	const newLanes = copy(lanes);

	let step;
	if (actionId === 'wait') {
		step = instantiateWait();
	} else {
		const action = availableActions.find((action) => action.id === actionId);
		if (!action) {
			console.error(`Action with id ${actionId} not found`);
			return null;
		}
		step = instantiateAction(actionId, action);
	}

	setStepId(step.id);

	if (
		destinationDropId === 'above' ||
		destinationDropId === 'falloff-catcher-above' ||
		destinationDropId === 'mid' ||
		destinationDropId === 'falloff-catcher-mid'
	) {
		return [{ id: 'temp', steps: [step] }, ...newLanes];
	}

	if (destinationDropId === 'below' || destinationDropId === 'falloff-catcher-below') {
		return [...newLanes, { id: 'temp', steps: [step] }];
	}

	const toLaneIndex = newLanes.findIndex((lane) => lane.id === destinationDropId);
	const toIndex = result.destination.index;
	const toLane = newLanes[toLaneIndex];

	// insert step into destination lane
	toLane.steps.splice(toIndex, 0, step);

	return newLanes;
}

function swap(lanes: ExperimentLaneVO[], result: DropResult): ExperimentLaneVO[] | null {
	if (!result.destination) {
		return null;
	}

	const destinationDropId = result.destination.droppableId.substring(WorkspaceLaneMaker.length);
	const sourceDropId = result.source.droppableId.substring(WorkspaceLaneMaker.length);

	const newLanes = copy(lanes);
	const fromLaneIndex = lanes.findIndex((lane) => lane.id === sourceDropId);
	const fromIndex = result.source.index;
	const fromLane = newLanes[fromLaneIndex];

	const toLaneIndex = lanes.findIndex((lane) => lane.id === destinationDropId);
	const toIndex = result.destination.index;
	const toLane = newLanes[toLaneIndex];

	if (destinationDropId === 'above' || destinationDropId === 'falloff-catcher-above') {
		const step = removeStep(fromLane, fromIndex);
		return [{ id: 'temp', steps: [step] }, ...newLanes];
	}

	if (destinationDropId === 'below' || destinationDropId === 'falloff-catcher-below') {
		const step = removeStep(fromLane, fromIndex);
		return [...newLanes, { id: 'temp', steps: [step] }];
	}

	if (fromLaneIndex === toLaneIndex && fromIndex === toIndex) {
		return null;
	}

	// remove step from source lane...
	const step = removeStep(fromLane, fromIndex);
	// ...and insert step into destination lane
	toLane.steps.splice(toIndex, 0, step);

	return newLanes;
}

function moveLane(lanes: ExperimentLaneVO[], fromIndex: number, toIndex: number): ExperimentLaneVO[] {
	const newLanes = lanes.slice();
	const [lane] = newLanes.splice(fromIndex, 1);
	newLanes.splice(toIndex, 0, lane);
	return newLanes;
}

function assignIds(lanes: ExperimentLaneVO[]): ExperimentLaneVO[] {
	lanes.forEach((lane, i) => (lane.id = `${i}`));
	return lanes;
}

function copy(lanes: ExperimentLaneVO[]): ExperimentLaneVO[] {
	return lanes.map((lane) => ({ ...lane, steps: lane.steps.map((step) => ({ ...step })) }));
}

function removeStep(lane: ExperimentLaneVO, index: number): BaseExperimentStepVOUnion {
	const [step] = lane.steps.splice(index, 1);
	return step;
}

export function correctDropAnimation(
	snapshot: DraggableStateSnapshot,
	style?: DraggingStyle | NotDraggingStyle | undefined,
): DraggingStyle | NotDraggingStyle | undefined {
	if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
		return style;
	}

	if (snapshot.draggingOver?.startsWith(WorkspaceLaneFalloffCatcherMaker)) {
		const { moveTo } = snapshot.dropAnimation;

		let y = moveTo.y + 8;
		if (snapshot.draggingOver === `${WorkspaceLaneFalloffCatcherMaker}above`) {
			const domElement = document.getElementById(snapshot.draggingOver);
			const rect = domElement?.getBoundingClientRect();
			if (rect) {
				y += rect.height - 60;
			}
		} else if (snapshot.draggingOver === `${WorkspaceLaneFalloffCatcherMaker}mid`) {
			const domElement = document.getElementById(snapshot.draggingOver);
			const rect = domElement?.getBoundingClientRect();
			if (rect) {
				y += rect.height / 2 - 30;
			}
		}

		// patching the existing style
		return {
			...style,
			transform: `translate(${moveTo.x + 24}px, ${y}px)`,
			// cannot be 0, but make it super tiny
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			transitionDuration: '0.5s',
		};
	}
	return style;
}

export function correctLaneDropAnimation(
	snapshot: DraggableStateSnapshot,
	style?: DraggingStyle | NotDraggingStyle | undefined,
): DraggingStyle | NotDraggingStyle | undefined {
	if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
		return style;
	}

	if (snapshot.draggingOver === WorkspaceLanesMaker) {
		const { moveTo } = snapshot.dropAnimation;
		const y = moveTo.y + 30;

		// patching the existing style
		return {
			...style,
			transform: `translate(${moveTo.x}px, ${y}px)`,
			// cannot be 0, but make it super tiny
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			transitionDuration: '0.5s',
		};
	}
	return style;
}
