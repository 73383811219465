/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ActionVO,
	EnvironmentSummaryVO,
	ExperimentLaneVO,
	ExperimentStepActionVO,
	OccuranceVO,
	TemplateVO,
	TrackingCreationMethodVO,
} from 'ui-api';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { ReactElement, useMemo, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { suppressSubmitOnEnter } from 'utils/form';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { useHistory } from 'url/hooks';
import { Form, Formik } from 'formik';
import { cloneDeep } from 'lodash';

import UnkownActionsTemplateModal from './UnknownActionsTemplateModal';
import { createExperimentRequestFromTemplate } from './utils';
import LoadingTemplateModal from './LoadingTemplateModal';
import PlaceholderSyncJob from './PlaceholderSyncJob';
import ValidationHandler from './ValidationHandler';
import MetadataSyncJob from './MetadataSyncJob';
import { ampli } from '../../../ampli';

export type UseTemplateFormData = TemplateVO & {
	placeholdersMap: Map<string, OccuranceVO[]>;
	placeholderValuesMap: Map<string, string>;
	variablesMap: Map<string, OccuranceVO[]>;
	variableValuesMap: Map<string, string>;
	newExperimentTags?: string[];
	environmentId: string;
	variables: string[];
	__originalLanes: ExperimentLaneVO[];
	__originalExperimentName: string;
	__originalHypothesis: string;
};

interface UseTemplateFormLoadingHandlerProps {
	experimentCreationMethod: TrackingCreationMethodVO;
	newExperimentTags?: string[];
	children: ReactElement;
	template?: TemplateVO;
	templateId: string;
	onClose: () => void;
}

export default function UseTemplateFormLoadingHandler({
	experimentCreationMethod,
	newExperimentTags,
	templateId,
	template,
	children,
	onClose,
}: UseTemplateFormLoadingHandlerProps): ReactElement {
	const templateResult = usePromise(
		() => (template ? Promise.resolve(template) : Services.templatesApi.getTemplate(templateId)),
		[templateId],
	);

	const actions = usePromise(() => Services.actions.fetchActions(), []);

	const { environmentsOfCurrentTeam: environments } = useEnvironments();
	if (environments.length === 0 || !templateResult.value || !actions.value) {
		return <LoadingTemplateModal onClose={onClose} />;
	}

	return (
		<UseTemplateActionsChecker
			experimentCreationMethod={experimentCreationMethod}
			newExperimentTags={newExperimentTags}
			template={templateResult.value}
			environments={environments}
			actions={actions.value}
			onClose={onClose}
		>
			{children}
		</UseTemplateActionsChecker>
	);
}

interface UseTemplateActionsCheckerProps {
	experimentCreationMethod: TrackingCreationMethodVO;
	environments: EnvironmentSummaryVO[];
	newExperimentTags?: string[];
	children: ReactElement;
	template: TemplateVO;
	actions: ActionVO[];
	onClose: () => void;
}

function UseTemplateActionsChecker({
	experimentCreationMethod,
	newExperimentTags,
	environments,
	template,
	children,
	actions,
	onClose,
}: UseTemplateActionsCheckerProps): ReactElement {
	const unknownActions: string[] = useMemo(() => {
		const unknownActions: string[] = [];
		const availableActionIds = new Set(actions.map((action) => action.id));
		for (let iL = 0; iL < template.lanes.length; iL++) {
			const { steps } = template.lanes[iL];
			for (let iS = 0; iS < steps.length; iS++) {
				const step = steps[iS];
				if (step.type === 'action') {
					const action = step as ExperimentStepActionVO;
					if (!availableActionIds.has(action.actionId)) {
						unknownActions.push(action.actionId);
					}
				}
			}
		}
		return unknownActions;
	}, [template?.id, actions]);

	if (unknownActions.length > 0) {
		return <UnkownActionsTemplateModal unknownActions={unknownActions} onClose={onClose} />;
	}

	return (
		<UseTemplateForm
			experimentCreationMethod={experimentCreationMethod}
			newExperimentTags={newExperimentTags}
			environmentId={environments[0]?.id}
			template={template}
		>
			{children}
		</UseTemplateForm>
	);
}

interface UseTemplateFormProps {
	experimentCreationMethod: TrackingCreationMethodVO;
	newExperimentTags?: string[];
	children: ReactElement;
	environmentId: string;
	template: TemplateVO;
}

function UseTemplateForm({
	experimentCreationMethod,
	newExperimentTags,
	environmentId,
	template,
	children,
}: UseTemplateFormProps): ReactElement {
	const history = useHistory();
	const team = useTeam();

	const [__originalLanes] = useState(() => cloneDeep(template.lanes));
	const [__originalExperimentName] = useState(() => template.experimentName);
	const [__originalHypothesis] = useState(() => template.hypothesis);

	return (
		<Formik<UseTemplateFormData>
			initialValues={{
				...template,
				placeholderValuesMap: new Map(Array.from(template.placeholders).map(({ key }) => [key, ''])),
				variableValuesMap: new Map(),
				placeholdersMap: new Map(),
				variablesMap: new Map(),
				newExperimentTags: newExperimentTags,
				variables: [],
				environmentId,
				__originalLanes,
				__originalExperimentName,
				__originalHypothesis,
			}}
			// see <ValidationHandler /> for docs
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={(values) => {
				ampli.experimentTemplateUsedWizardStepCompleted({
					experiment_template_name: values.templateTitle,
					experiment_template_step: values.placeholders.length + 1,
					experiment_template_last_step: true,
					experiment_template_step_placeholder: values.placeholders[values.placeholders.length - 1]?.name,
					experiment_template_total_steps: values.placeholders.length + 1,
				});
				history.push('/experiments/edit/<new>/design', {
					preparedFormData: {
						actions: ['save', 'run'],
						initialValues: createExperimentRequestFromTemplate({
							formData: values,
							teamId: team.id,
							experimentCreationMethod,
							tags: newExperimentTags,
							capNameAndHypothesis: true,
						}),
						initialErrors: {},
					},
				});
			}}
		>
			<Form key={template.id} onKeyDown={suppressSubmitOnEnter} noValidate>
				<MetadataSyncJob />
				<PlaceholderSyncJob />
				<ValidationHandler />
				{children}
			</Form>
		</Formik>
	);
}
