/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Checkbox,
	Code,
	Container,
	Heading,
	Link,
	Message,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Pagination,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	Tooltip,
	userConfirm,
} from 'components';
import { IconActionKit, IconDelete, IconInformation, IconWarningCircle } from 'components/icons';
import { usePromiseWithReExecution } from 'utils/hooks/usePromiseWithReExecution';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import { localeCompareIgnoreCase, toTitleCase } from 'utils/string';
import EmptyListContent from 'components/List/EmptyListContent';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { ReactElement, useMemo, useState } from 'react';
import { debounce, startCase, toLower } from 'lodash';
import { usePromise } from 'utils/hooks/usePromise';
import invokePromise from 'utils/ignorePromise';
import { ActionIcon } from 'hocs/ActionIcon';
import { Services } from 'services/services';
import { ActionSummaryVO } from 'ui-api';

import { useEventEffect } from '../../../utils/hooks/useEventEffect';
import { useTrackExtensionsListViewed } from './ExtensionList';
import { ActionEvents } from '../../../services/actionsApi';
import Pill from '../../../components/Pill/Pill';
import { json } from '../../../utils/mediaTypes';
import { getLabel } from '../../../i18n/label';
import HelpText from '../components/HelpText';
import { ampli } from '../../../ampli';

export default function Actions(): ReactElement {
	const [actionsResult, refetch] = usePromiseWithReExecution(() => Services.actions.fetchActionSummaries(), []);
	const [showDetails, setShowDetails] = useState<ActionSummaryVO | null>(null);
	const [selectedActions, setSelectedActions] = useState<string[]>([]);

	const debouncedFetch = useMemo(() => debounce(refetch, 100), [refetch]);
	useEventEffect(debouncedFetch, ActionEvents, () => debouncedFetch.cancel);

	const [query, setQuery] = useState<string>('');
	const pageSize = 10;
	const [page, setPage] = useState(0);
	const sortedItems = actionsResult.value?.content ? sort(search(actionsResult.value.content, query)) : [];

	const items = sortedItems.slice(page * pageSize, (page + 1) * pageSize);
	const totalElements = sortedItems.length;
	const totalPages = Math.ceil(totalElements / pageSize);

	const unavailableItems = items.filter((a) => !a.reportedByAgents);
	const itemsWithMultipleVersions = items.filter((a) => a.reportedByAgentsInDifferentVersions);
	const itemsWithUnknownVersion = items.filter((a) => a.reportedByAgentsWithUnknownVersion);
	const unavailableItemsDeletable =
		unavailableItems.length > 0 && unavailableItems.find((a) => a._actions.includes('delete'));
	const itemsWithUnknownVersionDeletable =
		itemsWithUnknownVersion.length > 0 && itemsWithUnknownVersion.find((a) => a._actions.includes('delete'));
	const deleteEnabled = unavailableItemsDeletable || itemsWithUnknownVersionDeletable;

	useTrackExtensionsListViewed(
		actionsResult.loading ? undefined : 'actions',
		page,
		totalElements,
		totalPages,
		unavailableItems.map((action) => action.id),
		itemsWithMultipleVersions.map((action) => action.id),
		itemsWithUnknownVersion.map((action) => action.id),
	);

	const allSelected = selectedActions.length === totalElements;

	return (
		<>
			{showDetails && (
				<ModalOverlay open onClose={() => setShowDetails(null)}>
					{({ close }) => (
						<ActionOnAgentInfo
							summary={showDetails}
							close={close}
							onDeleteClick={() =>
								handleDeleteClick([showDetails], () => {
									setSelectedActions([]);
								})
							}
						/>
					)}
				</ModalOverlay>
			)}

			<Stack m="xLarge" size={'medium'}>
				<Stack direction={'horizontal'}>
					<HelpText sx={{ flex: '50%' }}>
						Actions are everything that the agent can run as part of experiments. For example, attacks, load-tests,
						health checks, metric comparisons and whatever else you can dream up. You can implement actions with our{' '}
						<Link href="https://github.com/steadybit/action-kit" external>
							ActionKit
						</Link>
						.
					</HelpText>
					{unavailableItems.length > 0 && (
						<Message variant={'danger'} title={'Missing Agents for Actions'} flex={'50%'}>
							Experiments using these actions will fail. If you removed the extensions providing these actions, you can
							safely delete the actions from Steadybit. If this is not on purpose, please check the extension and agent
							logs for errors.
						</Message>
					)}

					{itemsWithMultipleVersions.length > 0 && (
						<Message variant={'warning'} title={'Multiple Versions for Actions'} flex={'50%'}>
							You are using extensions in different versions which may lead to unexpected behavior.
							<Text variant={'smallStrong'}>Please update all your extensions to the same version.</Text>
						</Message>
					)}
					{itemsWithUnknownVersion.length > 0 && (
						<Message variant={'warning'} title={'Unversioned  Actions'} flex={'50%'}>
							We found unversioned actions. These actions are potentially unstable. Consider updating your extension to
							a released version. You can delete unversioned actions, agents will then be triggered to retransmit their
							current action definitions.
						</Message>
					)}
				</Stack>

				<Stack size={'xxSmall'}>
					<Container display="flex" alignItems="center" flexDirection="row-reverse" justifyContent="space-between">
						<ListHeaderSearch
							title="Search action"
							value={query ?? ''}
							setValue={(v) => {
								setQuery(v);
								setPage(0);
							}}
						/>

						{deleteEnabled ? (
							<Button
								disabled={selectedActions.length === 0}
								variant="chromelessSmall"
								color="neutral600"
								onClick={() =>
									handleDeleteClick(sortedItems.filter((a) => selectedActions.includes(a.id)) ?? [], () => {
										setSelectedActions([]);
									})
								}
							>
								<IconDelete mr="xSmall" ml="-xSmall" /> Delete selected Actions
								{selectedActions.length > 0 ? ' (' + selectedActions.length + ')' : ''}
							</Button>
						) : null}
					</Container>

					<Table width="100%">
						<TableHead>
							<TableRow>
								<TableHeadCell colSpan={2}>
									<Tooltip content={`Select all ${totalElements} Actions on all pages`}>
										<Stack direction="horizontal" size="xSmall" alignItems="center">
											<Checkbox
												checked={allSelected}
												onChange={() => setSelectedActions(!allSelected ? sortedItems.map((i) => i.id) : [])}
												flexShrink={0}
											/>
											<Text variant="tableHeader">{allSelected ? 'Deselect all' : 'Select all'}</Text>
										</Stack>
									</Tooltip>
								</TableHeadCell>
								<TableHeadCell>Name</TableHeadCell>
								<TableHeadCell>Id</TableHeadCell>
								<TableHeadCell>Kind</TableHeadCell>
								<TableHeadCell>Target</TableHeadCell>
								<TableHeadCell>Details</TableHeadCell>
							</TableRow>
						</TableHead>
						{!actionsResult.value ? (
							<TableBody>
								<>
									<TableLoadingRow numColumns={7} />
									<TableLoadingRow numColumns={7} />
									<TableLoadingRow numColumns={7} />
								</>
							</TableBody>
						) : (
							<TableBody>
								{items.map((a) => {
									const props: ActionSummaryTableRowProps = {
										action: a,
										onShowDetailsClick: () => setShowDetails(a),
										selected: selectedActions.includes(a.id),
									};
									if (a._actions.includes('delete')) {
										props.onSelectClick = () =>
											setSelectedActions((prev) =>
												prev.includes(a.id) ? prev.filter((id) => id !== a.id) : [...prev, a.id],
											);
									}
									return <ActionSummaryTableRow key={a.id} {...props} />;
								})}
							</TableBody>
						)}
					</Table>

					{actionsResult.value && items.length === 0 && (
						<EmptyListContent
							icon={<IconActionKit variant="xxLarge" color="purple700" />}
							title={query ? 'No actions matching your filter found' : 'No actions found'}
						/>
					)}
				</Stack>

				<Pagination activePage={page} totalPages={totalPages} onClick={setPage} />
			</Stack>
		</>
	);
}

type ActionSummaryTableRowProps = {
	action: ActionSummaryVO;
	onShowDetailsClick?: () => void;
	onSelectClick?: () => void;
	selected?: boolean;
};

function ActionSummaryTableRow({
	action,
	onShowDetailsClick,
	onSelectClick,
	selected,
}: ActionSummaryTableRowProps): ReactElement {
	const definition = usePromise(() => Services.targets.getTargetDefinition(action.targetType), [action.targetType]);
	return (
		<TableRow hoverable onClick={onSelectClick}>
			{onSelectClick ? (
				<TableDataCell width={'30px'}>
					<Checkbox checked={selected} onChange={onSelectClick} onClick={(e) => e.stopPropagation()} />
				</TableDataCell>
			) : null}
			<TableDataCell
				colSpan={onSelectClick ? 1 : 2}
				maxWidth={'170px'}
				minWidth={'0px'}
				width={
					!action.reportedByAgents ||
					action.reportedByAgentsWithUnknownVersion ||
					action.reportedByAgentsInDifferentVersions
						? '170px'
						: '0px'
				}
			>
				<Stack size={'xSmall'} my={'xxSmall'}>
					{!action.reportedByAgents && (
						<Pill
							backgroundColor={'feedbackErrorLight'}
							color={'feedbackErrorDark'}
							sx={{
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Missing Agent
							</Text>
						</Pill>
					)}
					{action.reportedByAgentsWithUnknownVersion && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Unversioned
							</Text>
						</Pill>
					)}
					{action.reportedByAgentsInDifferentVersions && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Multiple Versions
							</Text>
						</Pill>
					)}
				</Stack>
			</TableDataCell>
			<TableDataCell>
				<ActionIcon id={action.id} mr={'small'} />
				{action.name}
			</TableDataCell>
			<TableDataCell>
				{action.id}
				{action.version != 'unknown' && ` @ ${action.version}`}
			</TableDataCell>
			<TableDataCell>{startCase(toLower(action.kind))}</TableDataCell>
			<TableDataCell>
				{definition.value ? toTitleCase(getLabel(definition.value.label, 2)) : action.targetType}
				{action.targetType && !definition.loading && !definition.value ? (
					<Pill
						backgroundColor={'coral100'}
						color={'feedbackErrorDark'}
						sx={{
							height: 32,
							borderRadius: 20,
							ml: 'xSmall',
						}}
					>
						<IconWarningCircle variant={'small'} mr={'xxSmall'} />
						<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
							Missing Type
						</Text>
					</Pill>
				) : null}
			</TableDataCell>
			<TableDataCell>
				<ButtonIcon onClick={onShowDetailsClick} variant={'small'} tooltip={'Show Details'}>
					<IconInformation size={'small'} />
				</ButtonIcon>
			</TableDataCell>
		</TableRow>
	);
}

const handleDeleteClick = (summaries: ActionSummaryVO[], onDeleted: () => void): void => {
	const count = summaries.length;
	invokePromise(async () => {
		if (
			(await userConfirm({
				width: '550px',
				title: 'Delete Actions',
				message: (
					<Stack>
						<Message variant={'warning'}>All experiments using one of these actions will become invalid.</Message>
						<Text>
							Do you really want to delete the following action{count > 1 ? 's' : ''}?
							<ul>
								{summaries.map((s) => (
									<li key={s.id}>
										<strong>{s.name}</strong>
										<br />
										{s.id}
									</li>
								))}
							</ul>
						</Text>
					</Stack>
				),
				actions: [
					{ value: 'cancel', label: 'Cancel' },
					{
						value: 'confirm',
						label: `Delete ${count == 1 ? 'Action' : `${count} Actions`}`,
						variant: 'primary',
					},
				],
			})) === 'confirm'
		) {
			try {
				await Promise.all(summaries.map((s) => Services.actions.deleteAction(s.id)));
				ampli.extensionTypeDeleted({
					url: window.location.href,
					extension_type: 'actions',
					extension_type_ids: summaries.map((s) => s.id),
				});
				Snackbar.dark('Actions deleted.', { toastId: 'action-deleted' });
				onDeleted();
			} catch (err) {
				Snackbar.error('Actions not deleted: ' + err.toString(), { toastId: 'action-deleted' });
			}
		}
	});
};

interface ActionOnAgentInfoProps {
	summary: ActionSummaryVO;
	onDeleteClick: () => void;
}

function ActionOnAgentInfo({
	summary,
	close,
	onDeleteClick,
}: ActionOnAgentInfoProps & { close: () => void }): ReactElement {
	const infos = usePromise(() => Services.actions.fetchActionOnAgentInfo(summary.id), [summary.id]);
	const action = usePromise(() => Services.actions.findAction(summary.id), [summary.id]);
	return (
		<ModalV2>
			<ModalHeaderV2 title={`"${summary.name}" Version Info`} onClose={close} />
			<ModalContentV2>
				<Stack>
					{summary.reportedByAgents ? (
						<>
							<HelpText>
								These are the agents the action is currently registered on. The version may differ between the agents.
							</HelpText>
							<Table width="100%">
								<TableHead>
									<TableRow>
										<TableHeadCell>Agent Hostname</TableHeadCell>
										<TableHeadCell>Action Version</TableHeadCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{infos.loading && (
										<>
											<TableLoadingRow numColumns={2} />
										</>
									)}
									{infos.value?.content
										?.slice()
										.sort((a, b) => localeCompareIgnoreCase(a.agent, b.agent))
										.map((a) => (
											<TableRow hoverable key={a.agent}>
												<TableDataCell>{a.agent}</TableDataCell>
												<TableDataCell>{a.versions.join(', ')}</TableDataCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
							{summary.reportedByAgentsWithUnknownVersion && (
								<Message variant={'warning'} title={'Unversioned Action'} flex={'50%'}>
									<p>
										The action is potentially unstable cause it is reported with an unknown version. Consider updating
										your extension to a released version. You can delete the unversioned action, agents will then be
										triggered to retransmit their current action definitions.
									</p>
									{onDeleteClick && (
										<Button
											variant={'primary'}
											onClick={() => {
												close();
												onDeleteClick();
											}}
											disabled={!summary._actions.includes('delete')}
										>
											<IconDelete mr={'xSmall'} /> Delete Action
										</Button>
									)}
								</Message>
							)}
						</>
					) : (
						<Message variant={'danger'} title={'Missing Agents for Action'} flex={'50%'}>
							<p>
								The action extension is not reported by any of the registered agents. <br />
								Experiments using these actions will fail. <br />
								If you removed the extension you can delete the action from Steadybit. <br />
								If this is not on purpose, please check the extension and agent logs for errors.
							</p>
							{onDeleteClick && (
								<Button
									variant={'primary'}
									onClick={() => {
										close();
										onDeleteClick();
									}}
									disabled={!summary._actions.includes('delete')}
								>
									<IconDelete mr={'xSmall'} /> Delete Action
								</Button>
							)}
						</Message>
					)}
					{action.value && (
						<>
							<Heading>Details</Heading>
							<Code
								lang="json"
								withCopyToClipboard
								withDownload={{
									fileName: `${action.value?.id}.json`,
									mediaType: json,
								}}
							>
								{JSON.stringify(action.value, undefined, 2)}
							</Code>
						</>
					)}
				</Stack>
			</ModalContentV2>
		</ModalV2>
	);
}

// exported for testing
export function sort(items: ActionSummaryVO[]): ActionSummaryVO[] {
	// first, sort by reportedByAgents
	// second, sort by reportedByAgentsWithUnknownVersion
	// third, sort by name

	return items.slice().sort((a, b) => {
		if (a.reportedByAgents && !b.reportedByAgents) {
			return 1;
		} else if (!a.reportedByAgents && b.reportedByAgents) {
			return -1;
		} else if (a.reportedByAgentsWithUnknownVersion && !b.reportedByAgentsWithUnknownVersion) {
			return -1;
		} else if (!a.reportedByAgentsWithUnknownVersion && b.reportedByAgentsWithUnknownVersion) {
			return 1;
		} else {
			return localeCompareIgnoreCase(a.name, b.name);
		}
	});
}

function search(items: ActionSummaryVO[], query: string): ActionSummaryVO[] {
	query = query.trim().toLowerCase();
	return items.filter((item) => {
		return item.id.toLowerCase().includes(query) || item.name.toLowerCase().includes(query);
	});
}
