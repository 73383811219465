/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { lazy, ReactElement, Suspense, useState } from 'react';
import SearchBar from 'pages/components/SearchBar/SearchBar';
import ListHeader from 'components/List/presets/ListHeader';
import { IconAdd, IconTemplate } from 'components/icons';
import { usePromise } from 'utils/hooks/usePromise';
import { Button, Stack, Text } from 'components';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';

import { useEventEffect } from '../../../utils/hooks/useEventEffect';
import ContentWrapper from '../components/ContentWrapper';
import HelpText from '../components/HelpText';
import TemplatesTable from './TemplatesTable';

const AdvertiseCard = lazy(() => import('pages/templates/components/AdvertiseCard'));

export default function TemplatesList(): ReactElement {
	const history = useHistory();
	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	const [updateSignal, setUpdateSignal] = useState(0);
	useEventEffect(
		() => {
			setUpdateSignal((signal) => signal + 1);
		},
		['experiment.template.created', 'experiment.template.updated', 'experiment.template.deleted'],
		() => {},
		[],
	);

	const targetDefinitionsResult = useTargetDefinitions();
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const searchObjectsResult = usePromise(() => Services.templatesApi.getSearchObjects(), [updateSignal]);

	return (
		<ContentWrapper>
			<ListHeader left={<ListHeaderTitle title="Templates" Icon={IconTemplate} />} />
			<Stack direction="horizontal" justifyContent="space-between" alignItems="center" mb="small">
				<HelpText>
					Make it easy for everyone to start with Chaos Engineering and boost knowledge sharing via experiment
					templates. Simply create new templates to ease experiment creation with a custom wizard.
				</HelpText>
			</Stack>

			<Stack size="large">
				{canCreateTemplates && (
					<Suspense fallback={<div />}>
						<AdvertiseCard
							wide
							description={{
								headline: 'Share Templates With the Community',
								subheadline: '>_ sharing is caring, don’t be shy',
								text: 'Share your knowledge with the community by publishing your templates to the hub!',
								ctaText: 'Share the love',
								ctaTo: 'https://docs.steadybit.com/install-and-configure/manage-experiment-templates#share-templates',
								ctaExternal: true,
							}}
						/>
					</Suspense>
				)}

				<div
					style={{
						display: 'grid',
						gridTemplateColumns: canCreateTemplates ? '1fr 200px' : '1fr',
						gap: '48px',
						alignItems: 'center',
					}}
				>
					<SearchBar
						mode="templateList"
						targetDefinitionsResult={targetDefinitionsResult}
						searchObjectsResult={searchObjectsResult}
						cypressTag="template-search-input"
						actionsResult={actionsResult}
						pathname="/templates"
					/>

					{canCreateTemplates && (
						<Button onClick={() => history.push('/settings/templates/<new>')} data-cy="create-template-button">
							<IconAdd mr="xSmall" ml="-xxSmall" />
							<Text variant="mediumStrong">Create template</Text>
						</Button>
					)}
				</div>

				<TemplatesTable />
			</Stack>
		</ContentWrapper>
	);
}
