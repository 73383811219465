/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { FormikErrors, useFormikContext } from 'formik';
import { Services } from 'services/services';
import { useEffect, useState } from 'react';
import { debounce, set } from 'lodash';

import { ExperimentFormValues } from './types';

/**
 * Why a custom validation handler and not formiks validation handler?
 * Because formik has 2 issue with validation:
 * - it cannot debound the validation
 * - validation is always called before submitting the form. Submit is NOT called if the validation fails.
 * which is horrible because one must be able to save an invalid experiment form.
 */
export default function ValidationHandler(): null {
	const { values, setErrors } = useFormikContext<ExperimentFormValues>();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { actions, ...experiment } = values;
	const [debouncedValidate] = useState(() =>
		debounce(
			async (v) => {
				const errors = await validate(v);
				setErrors(errors);
			},
			500,
			{ leading: true },
		),
	);

	const stableExperiment = useStableInstance(experiment);
	useEffect(() => {
		debouncedValidate(stableExperiment);
	}, [stableExperiment, debouncedValidate]);

	return null;
}

export async function validate(values: ExperimentFormValues): Promise<FormikErrors<ExperimentFormValues>> {
	const errors = {};
	try {
		const violations = await Services.experiments.validateExperiment(values);
		violations.forEach(({ field, message }) => {
			if (message === 'There are no targets matching your query.') {
				set(errors, field, { message, level: 'info' });
			} else {
				set(errors, field, { message, level: 'error' });
			}
		});
	} catch (error) {
		console.error('Could not validate experiment', { experimentKey: values.experimentKey, error });
	}
	return errors;
}
