/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { theme } from 'styles.v2/theme';
import { Container } from 'components';
import { ReactElement } from 'react';

import { WorkspaceLaneFalloffCatcherMaker } from '../DragAndDropHandler';
import LaneMarker from './LaneMarker';
import { LANE_STYLE } from './Lane';
import GhostStep from './GhostStep';
import './styles.css';

interface LaneFalloffCatcherProps {
	above?: boolean;
}

export default function LaneFalloffCatcher({ above }: LaneFalloffCatcherProps): ReactElement {
	const id = above ? 'above' : 'below';

	return (
		<Droppable droppableId={WorkspaceLaneFalloffCatcherMaker + id} direction="vertical" type="steps">
			{(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => {
				const { neutral100, neutral300, purple100 } = theme.colors;
				return (
					<div
						id={WorkspaceLaneFalloffCatcherMaker + id}
						ref={dropProvided.innerRef}
						style={{
							display: 'flex',
							flexDirection: above ? 'column-reverse' : 'column',
							backgroundColor: neutral100,
							width: '100%',
							flexGrow: 1,
						}}
						{...dropProvided.droppableProps}
					>
						<Container
							id="lane"
							className="lane"
							sx={{
								...LANE_STYLE,
								width: '100%',
								backgroundColor: dropSnapshot.isDraggingOver ? purple100 : neutral100,
								borderBottom: !above ? '1px solid ' + neutral300 : '1px solid transparent',
							}}
						>
							<LaneMarker>{''}</LaneMarker>
							{dropSnapshot.isDraggingOver && <GhostStep draggedOverWith={dropSnapshot.draggingOverWith} />}
						</Container>
						<div
							className="fake-background"
							style={{
								background: `repeating-linear-gradient(${
									above ? 0 : 180
								}deg, transparent, transparent 63px, ${neutral300} 63px, ${neutral300} 64px)`,
							}}
						/>
					</div>
				);
			}}
		</Droppable>
	);
}
