/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Checkbox,
	Code,
	Container,
	Heading,
	Link,
	Message,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Pagination,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	Tooltip,
	userConfirm,
} from 'components';
import { IconDelete, IconInformation, IconTargetEnrichmentRule, IconWarningCircle } from 'components/icons';
import { usePromiseWithReExecution } from 'utils/hooks/usePromiseWithReExecution';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import EmptyListContent from 'components/List/EmptyListContent';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { ReactElement, useMemo, useState } from 'react';
import { localeCompareIgnoreCase } from 'utils/string';
import { TargetEnrichmentRuleSummaryVO } from 'ui-api';
import invokePromise from 'utils/ignorePromise';
import { Services } from 'services/services';
import { json } from 'utils/mediaTypes';
import { debounce } from 'lodash';

import { useEventEffect } from '../../../utils/hooks/useEventEffect';
import { useTrackExtensionsListViewed } from './ExtensionList';
import { usePromise } from '../../../utils/hooks/usePromise';
import Pill from '../../../components/Pill/Pill';
import HelpText from '../components/HelpText';
import { ampli } from '../../../ampli';

export default function TargetEnrichmentRules(): ReactElement {
	const [targetEnrichmentRulesResult, refetch] = usePromiseWithReExecution(
		() => Services.targets.fetchTargetEnrichmentRulesSummaries(),
		[],
	);
	const [showDetails, setShowDetails] = useState<TargetEnrichmentRuleSummaryVO | null>(null);
	const [selectedTargetEnrichmentRules, setSelectedTargetEnrichmentRules] = useState<string[]>([]);

	const debouncedFetch = useMemo(() => debounce(refetch, 100), [refetch]);
	useEventEffect(
		debouncedFetch,
		[
			'targetType.created', //TODO Remove this once we removed enrichment rules from target types (3/34)
			'targetType.deleted', //TODO Remove this once we removed enrichment rules from target types
			'targetType.updated', //TODO Remove this once we removed enrichment rules from target types
			'targetEnrichmentRule.created',
			'targetEnrichmentRule.deleted',
			'targetEnrichmentRule.updated',
		],
		() => debouncedFetch.cancel,
	);

	const [query, setQuery] = useState<string>('');
	const pageSize = 10;
	const [page, setPage] = useState(0);
	const sortedItems = targetEnrichmentRulesResult.value?.content
		? sort(search(targetEnrichmentRulesResult.value.content, query))
		: [];

	const items = sortedItems.slice(page * pageSize, (page + 1) * pageSize);
	const totalElements = sortedItems.length;
	const totalPages = Math.ceil(totalElements / pageSize);

	const unavailableItems = items.filter((a) => !a.reportedByAgents);
	const itemsWithMultipleVersions = items.filter((a) => a.reportedByAgentsInDifferentVersions);
	const itemsWithUnknownVersion = items.filter((a) => a.reportedByAgentsWithUnknownVersion);
	const unavailableItemsDeletable =
		unavailableItems.length > 0 && unavailableItems.find((a) => a._actions.includes('delete'));
	const itemsWithUnknownVersionDeletable =
		itemsWithUnknownVersion.length > 0 && itemsWithUnknownVersion.find((a) => a._actions.includes('delete'));
	const deleteEnabled = unavailableItemsDeletable || itemsWithUnknownVersionDeletable;

	useTrackExtensionsListViewed(
		targetEnrichmentRulesResult.loading ? undefined : 'target_enrichment_rules',
		page,
		totalElements,
		totalPages,
		unavailableItems.map((action) => action.id),
		itemsWithMultipleVersions.map((action) => action.id),
		itemsWithUnknownVersion.map((action) => action.id),
	);

	const allSelected = selectedTargetEnrichmentRules.length === totalElements;

	return (
		<>
			{showDetails && (
				<ModalOverlay open onClose={() => setShowDetails(null)}>
					{({ close }) => (
						<TargetEnrichtmentRuleDetails
							summary={showDetails}
							close={close}
							onDeleteClick={() =>
								handleDeleteClick([showDetails], () => {
									setSelectedTargetEnrichmentRules([]);
								})
							}
						/>
					)}
				</ModalOverlay>
			)}

			<Stack m="xLarge">
				<Stack direction={'horizontal'}>
					<HelpText sx={{ flex: '50%' }}>
						Target enrichment rules describes how data is copied from one target to another. Enrichment rules are
						defined through extensions leveraging our{' '}
						<Link
							href="https://github.com/steadybit/discovery-kit/blob/main/docs/discovery-api.md#target-enrichment-rules"
							external
						>
							DiscoveryKit
						</Link>
						.
					</HelpText>
					{unavailableItems.length > 0 && (
						<Message variant={'warning'} title={'Missing Agents for Target Enrichment Rules'} flex={'50%'}>
							No agent is reporting these enrichment rules. <br />
							If you removed the extensions, you can remove the target enrichment rule from Steadybit by deleting them.
							<br />
							If this is not on purpose, please check the extension and agent logs for errors.
						</Message>
					)}
					{itemsWithMultipleVersions.length > 0 && (
						<Message variant={'warning'} title={'Multiple Versions for Target Enrichment Rules'} flex={'50%'}>
							We found target enrichment rules with multiple versions. <br />
							This could lead to unexpected behavior.
							<br />
							If this is not on purpose, please check the extension and agent logs for errors.
						</Message>
					)}
					{itemsWithUnknownVersion.length > 0 && (
						<Message variant={'warning'} title={'Unversioned Target Enrichment Rules'} flex={'50%'}>
							We found unversioned target enrichment rules. These are potentially unstable. Consider updating your
							extension to a released version. You can delete unversioned target enrichment rules, agents will then be
							triggered to retransmit their current target enrichment rules.
						</Message>
					)}
				</Stack>

				<Stack size={'xxSmall'}>
					<Container display="flex" alignItems="center" flexDirection="row-reverse" justifyContent="space-between">
						<ListHeaderSearch
							title="Search rule"
							value={query ?? ''}
							setValue={(v) => {
								setQuery(v);
								setPage(0);
							}}
						/>
						{deleteEnabled ? (
							<Stack direction={'horizontal'} justifyContent={'flex-end'} mb={'xxSmall'}>
								<Button
									disabled={selectedTargetEnrichmentRules.length === 0}
									variant={'chromelessSmall'}
									onClick={() =>
										handleDeleteClick(
											sortedItems.filter((t) => selectedTargetEnrichmentRules.includes(t.id)) ?? [],
											() => {
												setSelectedTargetEnrichmentRules([]);
											},
										)
									}
								>
									<IconDelete mr="xSmall" ml="-xSmall" /> Delete selected Target Enrichment Rules
									{selectedTargetEnrichmentRules.length > 0 ? ' (' + selectedTargetEnrichmentRules.length + ')' : ''}
								</Button>
							</Stack>
						) : null}
					</Container>

					<Table width={'100%'}>
						<TableHead>
							<TableRow>
								<TableHeadCell colSpan={2}>
									<Tooltip content={`Select all ${totalElements} Target Enrichment Rules on all pages`}>
										<Stack direction="horizontal" size="xSmall" alignItems="center">
											<Checkbox
												checked={allSelected}
												onChange={() =>
													setSelectedTargetEnrichmentRules(!allSelected ? sortedItems.map((i) => i.id) : [])
												}
												flexShrink={0}
											/>
											<Text variant="tableHeader">{allSelected ? 'Deselect all' : 'Select all'}</Text>
										</Stack>
									</Tooltip>
								</TableHeadCell>
								<TableHeadCell>Name</TableHeadCell>
								<TableHeadCell>Id</TableHeadCell>
								<TableHeadCell>Details</TableHeadCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{targetEnrichmentRulesResult.loading && (
								<>
									<TableLoadingRow numColumns={5} />
									<TableLoadingRow numColumns={5} />
									<TableLoadingRow numColumns={5} />
								</>
							)}
							{items.map((t) => {
								const props: TargetEnrichmentRuleRowProps = {
									summary: t,
									onShowDetailsClick: () => setShowDetails(t),
									selected: selectedTargetEnrichmentRules.includes(t.id),
								};
								if (t._actions.includes('delete')) {
									props.onSelectClick = () =>
										setSelectedTargetEnrichmentRules((prev) =>
											prev.includes(t.id) ? prev.filter((id) => id !== t.id) : [...prev, t.id],
										);
								}
								return <TargetEnrichmentRuleRow key={t.id} {...props} />;
							})}
						</TableBody>
					</Table>

					{targetEnrichmentRulesResult.value && items.length === 0 && (
						<EmptyListContent
							icon={<IconTargetEnrichmentRule variant="xxLarge" color="purple700" />}
							title={
								query ? 'No target enrichment rules matching your filter found' : 'No target enrichment rules found'
							}
						/>
					)}
				</Stack>

				<Pagination activePage={page} totalPages={totalPages} onClick={setPage} />
			</Stack>
		</>
	);
}

type TargetEnrichmentRuleRowProps = {
	summary: TargetEnrichmentRuleSummaryVO;
	onShowDetailsClick?: () => void;
	onSelectClick?: () => void;
	selected?: boolean;
};

function TargetEnrichmentRuleRow({
	summary,
	selected,
	onSelectClick,
	onShowDetailsClick,
}: TargetEnrichmentRuleRowProps): ReactElement {
	return (
		<TableRow hoverable onClick={onSelectClick}>
			{onSelectClick ? (
				<TableDataCell width={'30px'}>
					<Checkbox checked={selected} onChange={onSelectClick} onClick={(e) => e.stopPropagation()} />
				</TableDataCell>
			) : null}
			<TableDataCell
				colSpan={onSelectClick ? 1 : 2}
				maxWidth={'170px'}
				minWidth={'0px'}
				width={
					!summary.reportedByAgents ||
					summary.reportedByAgentsWithUnknownVersion ||
					summary.reportedByAgentsInDifferentVersions
						? '170px'
						: '0px'
				}
			>
				<Stack size={'xSmall'} my={'xxSmall'}>
					{!summary.reportedByAgents && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Missing Agent
							</Text>
						</Pill>
					)}
					{summary.reportedByAgentsWithUnknownVersion && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Unversioned
							</Text>
						</Pill>
					)}
					{summary.reportedByAgentsInDifferentVersions && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								ml: 'xxSmall',
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Multiple Versions
							</Text>
						</Pill>
					)}
				</Stack>
			</TableDataCell>
			<TableDataCell>{summary.label}</TableDataCell>
			<TableDataCell>
				{summary.id}
				{summary.version != 'unknown' && ` @ ${summary.version}`}
			</TableDataCell>
			<TableDataCell>
				<ButtonIcon onClick={onShowDetailsClick} variant={'small'} tooltip={'Show Details'}>
					<IconInformation size={'small'} />
				</ButtonIcon>
			</TableDataCell>
		</TableRow>
	);
}

const handleDeleteClick = (summaries: TargetEnrichmentRuleSummaryVO[], onDeleted: () => void): void => {
	const count = summaries.length;
	invokePromise(async () => {
		if (
			(await userConfirm({
				width: '550px',
				title: 'Delete Target Enrichtment Rules',
				message: (
					<>
						<Stack>
							<Text>
								Do you really want to delete the following target enrichment rule{count > 1 ? 's' : ''}?
								<ul>
									{summaries.map((s) => (
										<li key={s.id}>
											<strong>{s.label}</strong>
											<br />
											{s.id}
										</li>
									))}
								</ul>
							</Text>
						</Stack>
					</>
				),
				actions: [
					{ value: 'cancel', label: 'Cancel' },
					{
						value: 'confirm',
						label: `Delete ${count == 1 ? 'Target Enrichment Rule' : `${count} Target Enrichment Rules`}`,
						variant: 'primary',
					},
				],
			})) === 'confirm'
		) {
			try {
				await Promise.all(summaries.map((s) => Services.targets.deleteTargeEnrichmentRule(s.id)));
				ampli.extensionTypeDeleted({
					url: window.location.href,
					extension_type: 'target_enrichment_rules',
					extension_type_ids: summaries.map((s) => s.id),
				});
				Snackbar.dark('Target Enrichment Rule deleted.', { toastId: 'target-enrichment-rule-deleted' });
				onDeleted();
			} catch (err) {
				Snackbar.error('Target Enrichment Rule not deleted: ' + err.toString(), {
					toastId: 'target-enrichment-rule-deleted',
				});
			}
		}
	});
};

interface TargetEnrichmentRuleDetailsProps {
	summary: TargetEnrichmentRuleSummaryVO;
	onDeleteClick: () => void;
}

function TargetEnrichtmentRuleDetails({
	summary,
	close,
	onDeleteClick,
}: TargetEnrichmentRuleDetailsProps & { close: () => void }): ReactElement {
	const infos = usePromise(() => Services.targets.fetchTargetEnrichmentRuleOnAgentInfo(summary.id), [summary.id]);
	const definition = usePromise(() => Services.targets.fetchTargetEnrichmentRule(summary.id), [summary.id]);
	return (
		<ModalV2>
			<ModalHeaderV2 title={`"${summary.label}" Version Info`} onClose={close} />
			<ModalContentV2>
				<Stack>
					{summary.reportedByAgents ? (
						<>
							<HelpText>
								These are the agents the target enrichment rule is currently registered on. The version may differ
								between the agents.
							</HelpText>
							<Table width="100%">
								<TableHead>
									<TableRow>
										<TableHeadCell>Agent Hostname</TableHeadCell>
										<TableHeadCell>Target Enrichment Rule Version</TableHeadCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{infos.loading && (
										<>
											<TableLoadingRow numColumns={2} />
										</>
									)}
									{infos.value?.content
										?.slice()
										.sort((a, b) => localeCompareIgnoreCase(a.agent, b.agent))
										.map((a) => (
											<TableRow hoverable key={a.agent}>
												<TableDataCell>{a.agent}</TableDataCell>
												<TableDataCell>{a.versions.join(', ')}</TableDataCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
							{summary.reportedByAgentsWithUnknownVersion && (
								<Message variant={'warning'} title={'Unversioned Target Enrichment Rule'} flex={'50%'}>
									<p>
										The target enrichment rule is potentially unstable cause it is reported with an unknown version.
										Consider updating your extension to a released version. You can delete the unversioned target
										enrichment rule, agents will then be triggered to retransmit their current target enrichment rules.
									</p>
									{onDeleteClick && (
										<Button
											variant={'primary'}
											onClick={() => {
												close();
												onDeleteClick();
											}}
											disabled={!summary._actions.includes('delete')}
										>
											<IconDelete mr={'xSmall'} /> Delete Target Enrichment Rule
										</Button>
									)}
								</Message>
							)}
						</>
					) : (
						<Message variant={'danger'} title={'Missing Agents for Target Enrichment Rule'} flex={'50%'}>
							<p>
								The target enrichment rule extension is not reported by any of the registered agents. <br />
								If you removed the extension you can remove the target enrichment rule from Steadybit by deleting it.{' '}
								<br />
								If this is not on purpose, please check the extension and agent logs for errors.
							</p>
							<Button
								variant={'primary'}
								onClick={() => {
									close();
									onDeleteClick();
								}}
								disabled={!summary._actions.includes('delete')}
							>
								<IconDelete mr={'xSmall'} /> Delete Target Enrichment Rule
							</Button>
						</Message>
					)}
					{definition.value && (
						<>
							<Heading>Details</Heading>
							<Code
								lang="json"
								withCopyToClipboard
								withDownload={{
									fileName: `${definition.value?.id}.json`,
									mediaType: json,
								}}
							>
								{JSON.stringify(definition.value, undefined, 2)}
							</Code>
						</>
					)}
				</Stack>
			</ModalContentV2>
		</ModalV2>
	);
}

// exported for testing
export function sort(items: TargetEnrichmentRuleSummaryVO[]): TargetEnrichmentRuleSummaryVO[] {
	// first, sort by reportedByAgents
	// second, sort by reportedByAgentsWithUnknownVersion
	// third, sort by name

	return items.slice().sort((a, b) => {
		if (a.reportedByAgents && !b.reportedByAgents) {
			return 1;
		} else if (!a.reportedByAgents && b.reportedByAgents) {
			return -1;
		} else if (a.reportedByAgentsWithUnknownVersion && !b.reportedByAgentsWithUnknownVersion) {
			return -1;
		} else if (!a.reportedByAgentsWithUnknownVersion && b.reportedByAgentsWithUnknownVersion) {
			return 1;
		} else {
			return localeCompareIgnoreCase(a.label, b.label);
		}
	});
}

function search(items: TargetEnrichmentRuleSummaryVO[], query: string): TargetEnrichmentRuleSummaryVO[] {
	query = query.trim().toLowerCase();
	return items.filter((item) => {
		return item.id.toLowerCase().includes(query) || item.label.toLowerCase().includes(query);
	});
}
