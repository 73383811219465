/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconChevronDoubleLeft, IconChevronDoubleRight } from 'components/icons';
import { Button, Container, Heading, Stack, StyleProp } from 'components';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import textEllipsis from 'utils/styleSnippets/textEllipsis';

interface SidebarProps {
	children: (collapsed: boolean) => ReactNode | ReactNode[];
	title: string | ReactNode;
	sx?: StyleProp;
	width?: number;
	bg?: string;
}

export default function Sidebar({
	title,
	width = 380,
	bg = 'neutral100',
	sx = {},
	children,
}: SidebarProps): ReactElement {
	const [collapsed, setCollapsed] = useState(window.document.body.clientWidth < 1440);
	const [debouncedCollapsed, setDebouncedCollapsed] = useState(collapsed);

	useEffect(() => {
		if (collapsed) {
			setDebouncedCollapsed(collapsed);
			return;
		}

		const timeout = setTimeout(() => {
			setDebouncedCollapsed(collapsed);
		}, 200);
		return () => clearTimeout(timeout);
	}, [collapsed]);

	return (
		<Container
			as="aside"
			sx={{
				width: collapsed ? 96 : width,
				height: '100%',
				px: 'medium',
				pb: '1rem',
				flexShrink: 0,
				overflowY: 'auto',
				transition: 'width 0.2s ease-in-out, max-width 0.2s ease-in-out',
				backgroundColor: bg,
				borderRight: '1px solid',
				borderColor: 'neutral200',
				...sx,
			}}
		>
			<Container display={'flex'} flexDirection="column" height="100%">
				<Container display={'flex'} justifyContent="space-between" alignItems="center" mt={'medium'}>
					<Heading variant="large" as="h1" m="0" sx={{ ...textEllipsis }}>
						{collapsed ? <>&nbsp;</> : title}
					</Heading>
					<Button
						variant={'chromeless'}
						onClick={(e) => {
							e.stopPropagation();
							setCollapsed(!collapsed);
						}}
					>
						{collapsed ? <IconChevronDoubleRight variant="large" /> : <IconChevronDoubleLeft variant="large" />}
					</Button>
				</Container>

				<Stack height={'100%'} justifyContent={'space-between'} size="large">
					{children(debouncedCollapsed)}
				</Stack>
			</Container>
		</Container>
	);
}
