/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { IconEnvironment, IconEnvironmentGlobal } from 'components/icons';
import DropdownPill from 'components/Select/Dropdown/DropdownPill';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { Spinner } from 'components/Spinner';
import { ReactElement } from 'react';

import EnvironmentsDropdownContent from './EnvironmentsDropdownContent';

interface EnvironmentSelectorProps {
	selectedEnvironmentId: string | undefined;
	isLandscapeView?: boolean;
	hasError?: boolean;
	disabled?: boolean;
	teamId: string;
	selectEnvironmentId: (v: string) => void;
}

export default function EnvironmentSelector({
	isLandscapeView = false,
	selectedEnvironmentId,
	hasError = false,
	disabled = false,
	teamId,
	selectEnvironmentId,
}: EnvironmentSelectorProps): ReactElement {
	const teamResult = usePromise(() => Services.teams.fetchTeam(teamId), [teamId]);
	const { environments, isGlobalSelected, selectedEnvironment, environmentsIsLoading } =
		useEnvironments(selectedEnvironmentId);

	const allowedEnvironments = teamResult.value?.allowedEnvironments || [];

	hasError =
		hasError ||
		(selectedEnvironmentId && !environmentsIsLoading && !allowedEnvironments.includes(selectedEnvironmentId)
			? true
			: false) ||
		(!selectedEnvironmentId && !environmentsIsLoading);

	const environmentName = selectedEnvironment?.name
		? selectedEnvironment.name
		: environmentsIsLoading
			? ''
			: selectedEnvironmentId
				? 'Unknown'
				: 'select an environment';

	return (
		<DropdownPill
			Icon={isGlobalSelected ? IconEnvironmentGlobal : IconEnvironment}
			variant={hasError ? 'error' : 'default'}
			placement="bottom-start"
			value={environmentName}
			disabled={disabled}
			onValueChanged={selectEnvironmentId}
		>
			{({ selectItem }) => (
				<DropdownContentFrame maxHeight={500} sx={{ borderRadius: '4px' }}>
					{environmentsIsLoading || teamResult.loading ? (
						<Spinner variant={'small'} />
					) : (
						<EnvironmentsDropdownContent
							environments={environments.filter(
								(environment) =>
									environment.id === selectedEnvironmentId || allowedEnvironments.includes(environment.id),
							)}
							selectedEnvironmentId={selectedEnvironmentId}
							allowedEnvironments={allowedEnvironments}
							isLandscapeView={isLandscapeView}
							disabled={disabled}
							selectEnvironmentId={selectItem}
						/>
					)}
				</DropdownContentFrame>
			)}
		</DropdownPill>
	);
}
