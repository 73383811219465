/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	CheckboxChecked,
	Container,
	FormCheckbox,
	Label,
	SettingsGroup,
	SettingsGroupItem,
	SettingsGroupProps,
	Stack,
	Text,
	Tooltip,
} from 'components';
import useActionHierarchy from 'experiment/actions/useActionHierarchy';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { IconChevronDown, IconChevronUp } from 'components/icons';
import React, { ReactElement, ReactNode, useState } from 'react';
import { ActionVO, TargetTypeDescriptionVO } from 'ui-api';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import ListSelectButton from 'hocs/ListSelectButton';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';

import { TeamFormValues } from './teamTypes';

export const ActionPermissions: React.VFC<{ readonly?: boolean; readonlyExplanation?: ReactNode }> = ({
	readonly,
	readonlyExplanation,
}) => {
	const formik = useFormikContext<TeamFormValues>();
	const [actions] = useAsyncState(() => Services.actions.fetchActions());
	const targetDefinitions = useTargetDefinitions();

	const isAllSelected = actions.value?.length === formik.values.allowedActions?.length;
	const isSomeSelected = formik.values.allowedActions?.length > 0;

	return (
		<Stack size="none">
			<Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Stack direction="vertical" size="xxSmall" mr={'small'}>
					<Label>Select Allowed Actions</Label>
					<Text variant={'small'} color={'neutral600'}>
						Define which actions can be executed by this team in an experiment.
					</Text>
				</Stack>

				{!readonly && (
					<Stack direction="horizontal" size="xSmall">
						{!isAllSelected && (
							<ListSelectButton
								label="Select all"
								onClick={() => formik.setFieldValue('allowedActions', actions.value?.map((action) => action.id) ?? [])}
							/>
						)}
						{!isAllSelected && isSomeSelected && <Text>/</Text>}
						{isSomeSelected && (
							<ListSelectButton label="Clear all" onClick={() => formik.setFieldValue('allowedActions', [])} />
						)}
					</Stack>
				)}
			</Container>
			{actions.value && targetDefinitions.value && (
				<ActionChooser
					mt={'xSmall'}
					actions={actions.value.filter((action) => !action.deleted)}
					targetDefinitions={targetDefinitions.value}
					selectedActionIds={formik.values.allowedActions ?? []}
					onSelectActionIds={(attackIds) => formik.setFieldValue('allowedActions', attackIds)}
					readonly={!!readonly}
					readonlyExplanation={readonlyExplanation}
				/>
			)}
		</Stack>
	);
};

type ActionChooserProps = SettingsGroupProps & {
	readonly: boolean;
	readonlyExplanation?: ReactNode;
	actions: ActionVO[];
	targetDefinitions: TargetTypeDescriptionVO[];
	selectedActionIds: string[];
	onSelectActionIds: (actionIds: string[]) => void;
};

const ActionChooser: React.VFC<ActionChooserProps> = ({
	readonly,
	readonlyExplanation,
	actions,
	selectedActionIds,
	onSelectActionIds,
	...props
}) => {
	const [expandedTargetTypes, setExpandedTargetTypes] = useState<string[]>([]);

	const isTargetTypeChecked = (typeActions: ActionVO[]): CheckboxChecked => {
		const actionIdsWithTargetType = typeActions.map((action) => action.id);
		if (actionIdsWithTargetType.every((actionId) => selectedActionIds.includes(actionId))) {
			return true;
		} else if (actionIdsWithTargetType.every((actionId) => !selectedActionIds.includes(actionId))) {
			return false;
		} else {
			return 'Indeterminate';
		}
	};

	const handleTargetTypeClick = (actions: ActionVO[]): void => {
		// Not All selected -> Add all
		const targetTypeChecked = isTargetTypeChecked(actions);
		const addAll = targetTypeChecked === false || targetTypeChecked === 'Indeterminate';

		const newSelectedActionIds = [...selectedActionIds];
		actions
			.map((action) => action.id)
			.forEach((actionId) => {
				if (addAll && !selectedActionIds.includes(actionId)) {
					newSelectedActionIds.push(actionId);
				}
				if (!addAll && selectedActionIds.includes(actionId)) {
					newSelectedActionIds.splice(newSelectedActionIds.indexOf(actionId), 1);
				}
			});
		onSelectActionIds(newSelectedActionIds);
	};

	const actionCategories = useActionHierarchy({});

	return (
		<>
			{actions.length === 0 ? <Text>No Actions found. Are any agents registered?</Text> : null}
			<SettingsGroup {...props}>
				{actionCategories.map((category) =>
					category.targetDefinition ? (
						<ActionCategory
							key={category.label}
							readonly={readonly}
							readonlyExplanation={readonlyExplanation}
							label={category.label}
							actions={[
								...(category.actions || []),
								...(category.subCategories ? category.subCategories.flatMap((sc) => sc.actions) : []),
							].map((a) => a.action)}
							targetDefinition={category.targetDefinition}
							expandedTargetTypes={expandedTargetTypes}
							setExpandedTargetTypes={setExpandedTargetTypes}
							selectedActionIds={selectedActionIds}
							onSelectActionIds={onSelectActionIds}
							isTargetTypeChecked={isTargetTypeChecked}
							handleTargetTypeClick={handleTargetTypeClick}
						/>
					) : null,
				)}
			</SettingsGroup>
		</>
	);
};

interface ActionCategoryProps {
	readonly: boolean;
	readonlyExplanation?: ReactNode;
	label: string;
	actions: ActionVO[];
	targetDefinition: TargetTypeDescriptionVO;
	expandedTargetTypes: string[];
	setExpandedTargetTypes: (targetTypes: string[]) => void;
	selectedActionIds: string[];
	onSelectActionIds: (actionIds: string[]) => void;
	isTargetTypeChecked: (typeActions: ActionVO[]) => CheckboxChecked;
	handleTargetTypeClick: (targetType: ActionVO[]) => void;
}

function ActionCategory({
	readonly,
	readonlyExplanation,
	actions,
	targetDefinition,
	expandedTargetTypes,
	label,
	handleTargetTypeClick,
	isTargetTypeChecked,
	setExpandedTargetTypes,
	selectedActionIds,
	onSelectActionIds,
}: ActionCategoryProps): ReactElement {
	const isExpanded = expandedTargetTypes.indexOf(targetDefinition.id) >= 0;

	return (
		<SettingsGroupItem
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (!readonly) {
					handleTargetTypeClick(actions);
				}
			}}
			sx={{
				'& + [data-settings-group-item], [data-settings-group-item] + &, [data-settings-group-header] + &': {
					borderTop: '1px solid',
					borderColor: 'neutral200',
				},
			}}
		>
			<Container display="flex" alignItems="center">
				<Tooltip disabled={!readonlyExplanation} content={readonlyExplanation}>
					<FormCheckbox
						disabled={readonly}
						name={targetDefinition.id}
						label=""
						checked={isTargetTypeChecked(actions)}
					/>
				</Tooltip>
				<Container mx={'small'} bg={'neutral200'} height={40} width={'1px'} />
				<Label ml={'xxSmall'} variant={'mediumMedium'}>
					{label}
				</Label>
				<ButtonIcon
					ml="auto"
					variant="small"
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						if (isExpanded) {
							setExpandedTargetTypes(expandedTargetTypes.filter((type) => type !== targetDefinition.id));
						} else {
							setExpandedTargetTypes(expandedTargetTypes.concat(targetDefinition.id));
						}
					}}
				>
					{isExpanded ? <IconChevronUp /> : <IconChevronDown />}
				</ButtonIcon>
			</Container>
			{isExpanded && (
				<Container
					size={'medium'}
					m={'small'}
					sx={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						rowGap: 'small',
					}}
				>
					{actions.map((action) => (
						<ActionCheckbox
							disabled={readonly}
							disabledExplanation={readonlyExplanation}
							key={action.id}
							action={action}
							onSelectActionIds={onSelectActionIds}
							selectedActionIds={selectedActionIds}
						/>
					))}
				</Container>
			)}
		</SettingsGroupItem>
	);
}

const ActionCheckbox: React.VFC<{
	disabled: boolean;
	disabledExplanation?: ReactNode;
	action: ActionVO;
	selectedActionIds: string[];
	onSelectActionIds: (actionIds: string[]) => void;
}> = ({ action, selectedActionIds, onSelectActionIds, disabled, disabledExplanation }) => {
	const toggleActionSelection = (actionId: string): void => {
		if (selectedActionIds.includes(actionId)) {
			const newSelectedActionIds = [...selectedActionIds];
			newSelectedActionIds.splice(selectedActionIds.indexOf(action.id), 1);
			onSelectActionIds(newSelectedActionIds);
		} else {
			onSelectActionIds([...selectedActionIds, action.id]);
		}
	};

	return (
		<Container
			disabled={disabled}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				if (!disabled) {
					toggleActionSelection(action.id);
				}
			}}
		>
			<Tooltip disabled={!disabledExplanation} content={disabledExplanation}>
				<FormCheckbox
					disabled={disabled}
					name={action.id}
					label={action.name}
					checked={selectedActionIds.includes(action.id)}
					mr="auto"
				/>
			</Tooltip>
			<Text mx={26} color="neutral500">
				{action.description}
			</Text>
		</Container>
	);
};
