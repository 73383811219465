/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	CreateExperimentRequest,
	ExperimentLaneVO,
	ExperimentStepActionVO,
	FieldVO,
	LegacyExperimentTemplateSummaryVO,
	LegacyExperimentTemplateVO,
} from 'ui-api';
import { v4 as uuidv4 } from 'uuid';
import { flatMap } from 'lodash';

import { ExperimentApi } from './experimentApi';

export function getInitialFieldValues(fields: FieldVO[]): Record<string, string | number> {
	const initialValueEntries = fields.map((field) => {
		if (field.type === 'string[]' || field.type === 'key-value') {
			return [field.name, field.defaultValue ? parseStringArrayDefaultValue(field.defaultValue) : []];
		} else if (field.type === 'integer' || field.type === 'percentage' || field.type === 'stressng-workers') {
			return [field.name, parseInt(field.defaultValue) ?? ''];
		} else if (field.type === 'boolean') {
			return [field.name, field.defaultValue === true || field.defaultValue === 'true'];
		}
		return [field.name, field.defaultValue ?? (field.required ? '' : undefined)];
	});
	return Object.fromEntries(initialValueEntries);
}

function parseStringArrayDefaultValue(value: string): string[] {
	try {
		return JSON.parse(value);
	} catch {
		return [value];
	}
}

const TEMPLATE_ROLLING_DEPLOYMENT: LegacyExperimentTemplateVO = {
	id: 'k8s-rolling-deployment',
	name: 'Rolling deployment',
	description:
		'Make sure your pod´s HTTP endpoints work within expected success rates when performing a rolling update deployment.',
	hypothesis:
		'When performing a rolling update deployment, the pod´s HTTP endpoints work within expected success rates.',
	lanes: [
		{
			id: 'lane-1',
			steps: [
				{
					id: 'step-1-1',
					type: 'action',
					customLabel: 'Given all pods are ready',
					actionId: 'com.steadybit.extension_kubernetes.pod_count_check',
					parameters: {
						duration: '25s',
						podCountCheckMode: 'podCountEqualsDesiredCount',
					},
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-deployment',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.namespace',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.deployment',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
					},
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
				{
					id: 'step-1-2',
					type: 'action',
					customLabel: 'When deploying with a rolling update',
					actionId: 'com.steadybit.extension_kubernetes.rollout-restart',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-deployment',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.namespace',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.deployment',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
						percentage: 100,
					},
					parameters: {
						wait: false,
					},
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
				{
					id: 'step-1-3',
					type: 'action',
					customLabel: 'Then deployment rollout should complete within 120 seconds',
					actionId: 'com.steadybit.extension_kubernetes.rollout-status',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-deployment',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.namespace',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.deployment',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
						percentage: 100,
					},
					parameters: { duration: '120s' },
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
				{
					id: 'step-1-4',
					type: 'action',
					customLabel: 'Then all pods are ready again',
					actionId: 'com.steadybit.extension_kubernetes.pod_count_check',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-deployment',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.namespace',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.deployment',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
					},
					parameters: { duration: '25s' },
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
			],
		},
		{
			id: 'lane-2',
			steps: [
				{
					id: 'step-2-1',
					type: 'action',
					customLabel: 'Then pod´s http endpoint doesn´t crash success rates',
					actionId: 'com.steadybit.extension_http.check.periodically',
					parameters: {
						url: '',
						method: 'GET',
						headers: [],
						duration: '180s',
						statusCode: '200-299',
						readTimeout: '5s',
						successRate: 100,
						maxConcurrent: 5,
						connectTimeout: '5s',
						followRedirects: false,
						requestsPerSecond: 1,
					},
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
			],
		},
		{
			id: 'lane-3',
			steps: [
				{
					id: 'step-3-1',
					type: 'action',
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
					parameters: { duration: '180s' },
					customLabel: 'Watch Kubernetes deployment´s status',
					actionId: 'com.steadybit.extension_kubernetes.pod_count_metric',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-cluster',
						predicate: {
							key: 'k8s.cluster-name',
							operator: 'EQUALS',
							values: [null],
						},
					},
				},
			],
		},
		{
			id: 'lane-4',
			steps: [
				{
					id: 'step-4-1',
					type: 'action',
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
					parameters: { duration: '180s' },
					customLabel: 'Watch Kubernetes Event Logs',
					actionId: 'com.steadybit.extension_kubernetes.kubernetes_logs',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-cluster',
						predicate: {
							key: 'k8s.cluster-name',
							operator: 'EQUALS',
							values: [null],
						},
					},
				},
			],
		},
	],
};

const TEMPLATE_RIPPLING_OUTAGE: LegacyExperimentTemplateVO = {
	id: 'rippling-outage',
	name: 'Rippling Outage',
	description:
		'An outage of one application often entail outages of other applications.\nSimulate the successive outages like in a series of domino stones and check that your offered HTTP endpoint isn´t affected.',
	hypothesis: 'A HTTP endpoint offered by multiple pods remains available although related containers are crashing.',
	lanes: [
		{
			id: 'lane-1',
			steps: [
				{
					id: 'step-1-1',
					type: 'wait',
					customLabel: 'Wait until',
					parameters: {
						duration: '15s',
					},
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
				{
					id: 'step-1-2',
					type: 'action',
					customLabel: 'container crashes',
					actionId: 'com.steadybit.extension_container.stop',
					blastRadius: {
						targetType: 'com.steadybit.extension_container.container',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.namespace',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.deployment',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
						maximum: 1,
					},
					parameters: { graceful: false },
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
			],
		},
		{
			id: 'lane-2',
			steps: [
				{
					id: 'step-2-1',
					type: 'wait',
					customLabel: 'Wait until',
					parameters: {
						duration: '30s',
					},
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
				{
					id: 'step-2-2',
					type: 'action',
					actionId: 'com.steadybit.extension_container.stop',
					customLabel: 'a 2nd container crashes',
					blastRadius: {
						targetType: 'com.steadybit.extension_container.container',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.namespace',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.deployment',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
						maximum: 1,
					},
					parameters: { graceful: false },
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
			],
		},
		{
			id: 'lane-3',
			steps: [
				{
					id: 'step-3-1',
					type: 'wait',
					customLabel: 'Wait until',
					parameters: {
						duration: '45s',
					},
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
				{
					id: 'step-3-2',
					type: 'action',
					actionId: 'com.steadybit.extension_container.stop',
					customLabel: 'a 3rd container crashes',
					blastRadius: {
						targetType: 'com.steadybit.extension_container.container',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.namespace',
									operator: 'EQUALS',
									values: [null],
								},
								{
									key: 'k8s.deployment',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
						maximum: 1,
					},
					parameters: { graceful: false },
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
			],
		},
		{
			id: 'lane-4',
			steps: [
				{
					id: 'step-3-1',
					type: 'action',
					customLabel: 'Verify that the HTTP endpoint is working all the time',
					actionId: 'com.steadybit.extension_http.check.periodically',
					parameters: {
						url: '',
						method: 'GET',
						headers: [],
						duration: '90s',
						statusCode: '200-299',
						readTimeout: '5s',
						successRate: 100,
						maxConcurrent: 5,
						connectTimeout: '5s',
						followRedirects: false,
						requestsPerSecond: 1,
					},
					metricQueries: [],
					metricChecks: [],
					ignoreFailure: false,
				},
			],
		},
		{
			id: 'lane-5',
			steps: [
				{
					id: 'step-3-1',
					type: 'action',
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
					parameters: { duration: '90s' },
					customLabel: 'Watch Kubernetes deployment´s status',
					actionId: 'com.steadybit.extension_kubernetes.pod_count_metric',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-cluster',
						predicate: {
							key: 'k8s.cluster-name',
							operator: 'EQUALS',
							values: [null],
						},
					},
				},
			],
		},
		{
			id: 'lane-6',
			steps: [
				{
					id: 'step-4-1',
					type: 'action',
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
					parameters: { duration: '90s' },
					customLabel: 'Watch Kubernetes Event Logs',
					actionId: 'com.steadybit.extension_kubernetes.kubernetes_logs',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-cluster',
						predicate: {
							key: 'k8s.cluster-name',
							operator: 'EQUALS',
							values: [null],
						},
					},
				},
			],
		},
	],
};

const TEMPLATE_CHOKING_HOST_RESOURCES: LegacyExperimentTemplateVO = {
	id: 'choking-host-resources',
	name: 'Choking Host Resources',
	description:
		'Check what happens when one host has scarce resources, followed by a crashing container.\nIs your container starting up successfully or is it even scheduled on a new host with more resources?',
	hypothesis: 'A crashed application is rescheduled on a host with proper resources.',
	lanes: [
		{
			id: 'lane-1',
			steps: [
				{
					id: 'step-1-1',
					type: 'wait',
					parameters: {
						duration: '10s',
					},
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
				},
				{
					id: 'step-1-2',
					type: 'action',
					customLabel: 'When container´s host CPU is stressed',
					actionId: 'com.steadybit.extension_host.stress-cpu',
					blastRadius: {
						targetType: 'com.steadybit.extension_host.host',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'host.hostname',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
						maximum: 1,
					},
					parameters: {
						duration: '45s',
						cpuLoad: 100,
					},
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
				},
			],
		},
		{
			id: 'lane-2',
			steps: [
				{
					id: 'step-2-1',
					type: 'action',
					customLabel: 'Given all pods are ready',
					actionId: 'com.steadybit.extension_kubernetes.pod_count_check',
					parameters: {
						duration: '10s',
						podCountCheckMode: 'podCountEqualsDesiredCount',
					},
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-deployment',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
					},
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
				},
				{
					id: 'step-2-2',
					type: 'wait',
					parameters: {
						duration: '15s',
					},
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
				},
				{
					id: 'step-2-3',
					type: 'action',
					customLabel: 'All container on that host are stopped',
					actionId: 'com.steadybit.extension_container.stop',
					blastRadius: {
						targetType: 'com.steadybit.extension_container.container',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'host.hostname',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
						percentage: 100,
					},
					parameters: {},
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
				},
				{
					id: 'step-2-4',
					type: 'wait',
					parameters: {
						duration: '15s',
					},
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
				},
				{
					id: 'step-2-5',
					type: 'action',
					customLabel: 'Pods are eventually all ready again',
					actionId: 'com.steadybit.extension_kubernetes.pod_count_check',
					parameters: {
						duration: '100s',
						podCountCheckMode: 'podCountEqualsDesiredCount',
					},
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-deployment',
						predicate: {
							operator: 'AND',
							predicates: [
								{
									key: 'k8s.cluster-name',
									operator: 'EQUALS',
									values: [null],
								},
							],
						},
					},
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
				},
			],
		},
		{
			id: 'lane-3',
			steps: [
				{
					id: 'step-3-1',
					type: 'action',
					actionId: 'com.steadybit.extension_http.check.periodically',
					customLabel: 'Pod´s http endpoint works all the time within expected success rate',
					parameters: {
						url: '',
						method: 'GET',
						headers: [],
						duration: '60s',
						statusCode: '200-299',
						readTimeout: '5s',
						successRate: 100,
						maxConcurrent: 5,
						connectTimeout: '5s',
						followRedirects: false,
						requestsPerSecond: 1,
					},
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
				},
			],
		},
		{
			id: 'lane-4',
			steps: [
				{
					id: 'step-3-1',
					type: 'action',
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
					parameters: { duration: '150s' },
					customLabel: 'Watch Kubernetes deployment´s status',
					actionId: 'com.steadybit.extension_kubernetes.pod_count_metric',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-cluster',
						predicate: {
							key: 'k8s.cluster-name',
							operator: 'EQUALS',
							values: [null],
						},
					},
				},
			],
		},
		{
			id: 'lane-5',
			steps: [
				{
					id: 'step-4-1',
					type: 'action',
					ignoreFailure: false,
					metricQueries: [],
					metricChecks: [],
					parameters: { duration: '150s' },
					customLabel: 'Watch Kubernetes Event Logs',
					actionId: 'com.steadybit.extension_kubernetes.kubernetes_logs',
					blastRadius: {
						targetType: 'com.steadybit.extension_kubernetes.kubernetes-cluster',
						predicate: {
							key: 'k8s.cluster-name',
							operator: 'EQUALS',
							values: [null],
						},
					},
				},
			],
		},
	],
};
const TEMPLATES: LegacyExperimentTemplateVO[] = [
	TEMPLATE_ROLLING_DEPLOYMENT,
	TEMPLATE_RIPPLING_OUTAGE,
	TEMPLATE_CHOKING_HOST_RESOURCES,
];

function applyDefaults(values: Record<string, string | number>, fields?: FieldVO[]): Record<string, string | number> {
	return fields ? { ...getInitialFieldValues(fields), ...values } : values;
}

function replaceIds(lanes: ExperimentLaneVO[]): ExperimentLaneVO[] {
	return lanes.map(({ steps, ...lane }) => ({
		...lane,
		id: uuidv4(),
		steps: steps.map((step) => ({
			...step,
			id: uuidv4(),
		})),
	}));
}

export class LegacyExperimentsTemplateApi {
	private experiments: ExperimentApi;

	constructor(experiments: ExperimentApi) {
		this.experiments = experiments;
	}

	async fetchTemplates(): Promise<LegacyExperimentTemplateSummaryVO[]> {
		return TEMPLATES;
	}

	async instantiateTemplate(templateId: string): Promise<CreateExperimentRequest> {
		const template = TEMPLATES.find((t) => t.id === templateId);
		if (!template) {
			throw new Error(`Experiment Template ${templateId} not found`);
		}

		return {
			name: template.name,
			templateId: template.id,
			templateTitle: template.name,
			hypothesis: '',
			teamId: '',
			environmentId: '',
			externalId: '',
			lanes: replaceIds(template.lanes),
			webhookIds: [],
			variables: [],
			tags: [],
			creationMethod: 'UI_TEMPLATE',
		};
	}

	async applyDefaultParameters({ lanes, ...experiment }: CreateExperimentRequest): Promise<CreateExperimentRequest> {
		const allAttackIds = flatMap(lanes, (lane) => lane.steps)
			.filter((step) => step.type === 'action')
			.map((step) => (step as ExperimentStepActionVO).actionId);

		const [attacks] = await Promise.all([
			this.experiments.fetchActionParametersAsMap(experiment.environmentId, allAttackIds),
		]);

		return {
			...experiment,
			lanes: lanes.map(({ steps, ...lane }) => {
				return {
					...lane,
					steps: steps.map(({ parameters, ...step }) => {
						return {
							...step,
							parameters: step.type === 'action' ? applyDefaults(parameters, attacks.get(step.actionId)) : parameters,
						};
					}),
				};
			}),
		};
	}
}
