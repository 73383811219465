/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	Droppable,
	DroppableProvided,
	DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { Container, StyleProp } from 'components';
import { ExperimentLaneVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { correctLaneDropAnimation, WorkspaceLaneMaker } from '../DragAndDropHandler';
import { useEditorSettings } from '../useEditorSettings';
import { ExperimentError } from '../types';
import LaneMarker from './LaneMarker';
import GhostStep from './GhostStep';
import Steps from './Steps';
import './styles.css';

interface LaneProps {
	stepIdToError: Map<string, ExperimentError[]>;
	isDragDisabled: boolean;
	lane: ExperimentLaneVO;
	index: number;
}

export default function Lane({ lane, index, stepIdToError, isDragDisabled }: LaneProps): ReactElement {
	const { mode } = useEditorSettings();

	return (
		<Draggable key={lane.id} index={index} draggableId={WorkspaceLaneMaker + lane.id} isDragDisabled={isDragDisabled}>
			{(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
				<div
					ref={dragProvided.innerRef}
					{...dragProvided.draggableProps}
					style={correctLaneDropAnimation(dragSnapshot, dragProvided.draggableProps.style)}
				>
					<Droppable droppableId={WorkspaceLaneMaker + lane.id} direction="horizontal" type="steps">
						{(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => {
							return (
								<Container
									id="lane"
									className="lane"
									ref={dropProvided.innerRef}
									sx={{
										...LANE_STYLE,
										minWidth:
											mode === 'experiment' ? 'calc(100vw - 276px - 77px)' : 'calc(100vw - 276px - 77px - 77px)',
										backgroundColor: dropSnapshot.isDraggingOver ? theme.colors.neutral200 : theme.colors.neutral100,
									}}
									{...dropProvided.droppableProps}
								>
									{index !== undefined && (
										<div {...dragProvided.dragHandleProps}>
											<LaneMarker>{String(index + 1)}</LaneMarker>
										</div>
									)}
									{dropSnapshot.isDraggingOver && lane.steps.length === 0 && <GhostStep />}
									<Steps steps={lane.steps} stepIdToError={stepIdToError} />
								</Container>
							);
						}}
					</Droppable>
				</div>
			)}
		</Draggable>
	);
}

export const LANE_STYLE: StyleProp = {
	display: 'flex',
	alignItems: 'center',
	paddingRight: '560px',
	maxWidth: '100000px',
	borderTop: '1px solid ' + theme.colors.neutral300,
	borderBottom: '1px solid transparent',
	overflowX: 'clip',
};
