/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useEffect, useRef, useState } from 'react';
import { useDimensions } from 'utils/hooks/useDimensions';
import { AnimatePresence, motion } from 'framer-motion';
import { useClickAway, useToggle } from 'react-use';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';

import { DropdownComponentProps, GeneralDropdownProps } from './types';

interface DropdownProps<T> extends GeneralDropdownProps<T> {
	onValueChanged?: (value: T) => void;
	renderComponent: (props: DropdownComponentProps<T>) => ReactElement;
	popupUpdateSignal?: boolean;
}

const animate = {
	initial: { opacity: 0, y: -5 },
	animate: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: 0 },
	transition: { duration: 0.15 },
};

/**
 *
 * 🚨 ATTENTION 🚨
 *
 * There is an open ADT for this component: https://www.notion.so/steadybit/Multiple-Inputs-for-similar-Usecases-DropDown-ComboBox-6fa13829ba3b438db6598e902ce62d65
 * It triggers as soon as there are changes to this component.
 * So if you need to make changes, please refactor the stuff first and resolve the ADT.
 *
 * all affected components have this comment.
 *
 * 	-Johannes
 */
export default function Dropdown<T>({
	children,
	value,
	onValueChanged,
	renderComponent,
	width: externalWidth,
	onOpen,
	onClose,
	placement = 'bottom',
	popupUpdateSignal,
}: DropdownProps<T>): ReactElement {
	const [showMenu, setShowMenuInternal] = useToggle(false);
	const setShowMenu = (show: boolean): void => {
		if (showMenu === show) {
			return;
		}
		if (show) {
			if (onOpen) {
				onOpen();
			}
		} else if (onClose) {
			onClose();
		}
		setShowMenuInternal(show);
	};
	const openMenu = (): void => setShowMenu(true);
	const closeMenu = (): void => setShowMenu(false);
	const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);

	const selectItem = (item: T): void => {
		onValueChanged?.(item);
		closeMenu();
	};

	// as usePopper works only properly with useState (instead of References)
	// we need to keep a real reference around synced to the state for the useClickAway.
	const popperRef = useRef(popperEl);
	useEffect(() => {
		popperRef.current = popperEl;
		popperEl?.addEventListener('dropdown_close', () => closeMenu);
	}, [popperEl, closeMenu]);

	const referenceEl = useRef<HTMLButtonElement | HTMLInputElement>(null);
	const {
		styles,
		attributes,
		update: updatePopper,
	} = usePopper(referenceEl.current, popperEl, {
		placement,
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 6],
				},
			},
		],
	});

	useEffect(() => {
		const observer = new MutationObserver(() => {
			if (showMenu && updatePopper) {
				updatePopper();
			}
		});
		observer.observe(document, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
		};
	}, [updatePopper]);

	useClickAway(referenceEl, (e: MouseEvent) => {
		if (showMenu && !isDrowndownInPath(e)) {
			closeMenu();
		}
	});

	useEffect(() => {
		if (showMenu && updatePopper) {
			updatePopper();
		}
	}, [showMenu, updatePopper, popupUpdateSignal]);

	const [width, setWidth] = useState<number | string | undefined>(externalWidth || referenceEl.current?.clientWidth);
	const [, [widthOfDomElement]] = useDimensions<HTMLButtonElement | HTMLInputElement>(referenceEl);
	useEffect(
		() => setWidth(externalWidth && externalWidth !== '100%' ? externalWidth : widthOfDomElement),
		[externalWidth, widthOfDomElement],
	);

	return (
		<>
			{renderComponent({
				setShowMenu,
				showMenu,
				value,
				onValueChanged: onValueChanged
					? (_v: T) => {
							onValueChanged(_v);
							openMenu();
						}
					: onValueChanged,
				ref: referenceEl,
			})}

			{ReactDOM.createPortal(
				<AnimatePresence>
					{showMenu ? (
						<motion.div
							id="dropdownContainer"
							ref={setPopperEl}
							style={{
								...styles.popper,
								zIndex: 41,
								willChange: 'opacity, transform',
								width: 'fit-content',
							}}
							{...attributes.popper}
							{...animate}
							transformTemplate={(_, generated) => `${generated} ${styles.popper.transform ?? ''}`}
						>
							{children({ value, selectItem, width })}
						</motion.div>
					) : null}
				</AnimatePresence>,
				document.body,
			)}
		</>
	);
}

function isDrowndownInPath(e: MouseEvent): boolean {
	return (e.target as HTMLElement).closest('div[id^="dropdownContainer"]') !== null;
}
