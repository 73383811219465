/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface GhostStepProps {
	draggedOverWith?: string | null;
}

export default function GhostStep({ draggedOverWith }: GhostStepProps): ReactElement {
	return (
		<div
			key={draggedOverWith}
			style={{
				width: '218px',
				height: '40px',
				border: '2px dashed ' + theme.colors.slate,
				backgroundColor: theme.colors.neutral050,
				borderRadius: '4px',
			}}
		/>
	);
}
