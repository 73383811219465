/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { GetExperimentsPageResponse, GetExperimentsRequestVO } from 'ui-api';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { localeCompareIgnoreCase } from 'utils/string';
import { usePromise } from 'utils/hooks/usePromise';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { ampli } from 'ampli';

import { UrlState as ExperimentListUrlState, directionParam, sortParam } from '../urlParams';

export default function useRefreshingExperiments(): DataStreamResult<GetExperimentsPageResponse> {
	const [
		{ environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, pageParam, pageSizeParam },
	] = useState(() => createFilterParams('/experiments'));
	const [{ environmentIds, page, size, sort, direction, tags, targetTypes, actions, freeTextPhrases }] = useUrlState<
		UrlState & ExperimentListUrlState
	>([
		freeTextPhrasesParam,
		environmentIdParam,
		targetTypesParam,
		pageSizeParam,
		actionsParam,
		pageParam,
		tagsParam,
		sortParam,
		directionParam,
	]);

	const team = useTeam();

	const [reloadSignal, setReloadSignal] = useState(0);
	const experiments = usePromise(() => {
		if (
			tags.length > 0 ||
			environmentIds.length > 0 ||
			targetTypes.length > 0 ||
			actions.length > 0 ||
			freeTextPhrases.length > 0
		) {
			ampli.experimentListFiltered({
				filter_environments: environmentIds,
				filter_target_types: targetTypes,
				filter_texts: freeTextPhrases,
				filter_actions: actions,
				filter_tags: tags,
			});
		}

		const body: GetExperimentsRequestVO = {
			teamId: team.id,
			environmentIds,
			freeTextPhrases,
			targetTypes,
			actions,
			tags,
			pageable: {
				number: page,
				size,
				order: [
					{
						property: sort,
						direction,
					},
				],
			},
		};
		return Services.experiments.fetchExperiments(body);
	}, [
		createStableId(tags),
		createStableId(targetTypes),
		createStableId(actions),
		createStableId(freeTextPhrases),
		createStableId(environmentIds),
		page,
		size,
		sort,
		direction,
		team.id,
		reloadSignal,
	]);

	const debouncedFetchExperiments = useMemo(
		() => debounce(() => setReloadSignal(reloadSignal + 1), 1000, { leading: true }),
		[reloadSignal],
	);

	useEventEffect(
		(event) => {
			if (['schedule.created', 'schedule.updated', 'schedule.deleted'].includes(event.type)) {
				return;
			}
			if (event.type.startsWith('experiment.execution')) {
				return;
			}
			if (['experiment.created', 'killswitch.engaged', 'killswitch.disengaged'].includes(event.type)) {
				debouncedFetchExperiments();
			} else if (experiments.value?.content.some((ex) => ex.key === event.experimentKey)) {
				console.log({ event });

				debouncedFetchExperiments();
			}
		},
		[],
		debouncedFetchExperiments.cancel,
		[experiments.value?.content.map((ex) => ex.key).join(',')],
	);

	return experiments;
}

function createStableId(strings: string[] = []): string {
	return strings.slice().sort(localeCompareIgnoreCase).join(',');
}
