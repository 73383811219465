/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Stack, Text, Toggle, Tooltip } from 'components';
import { IconInformation } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { ReactElement } from 'react';

import { UrlState, includeNonPermittedParam, pageParam } from './urlParams';

export default function ResultsToggle(): ReactElement {
	const [{ includeNonPermitted }, , updateUrlState] = useUrlState<UrlState>([includeNonPermittedParam, pageParam]);

	return (
		<Stack direction="horizontal" size="large" justifyContent="space-between">
			<Text variant="largeStrong">Results</Text>
			<Stack direction="horizontal" size="large">
				<Stack direction="horizontal" size="xSmall" alignItems="center">
					<Text variant="mediumStrong">Show templates with missing permissions</Text>
					<Tooltip
						content={
							<>
								<div>Show templates that include actions that your team misses permissions.</div>
								<div>You can remove these actions in the created experiment.</div>
							</>
						}
					>
						<div>
							<IconInformation variant="small" color="neutral600" />
						</div>
					</Tooltip>
				</Stack>
				<Toggle
					checked={includeNonPermitted}
					onChange={() => updateUrlState({ page: 0, includeNonPermitted: !includeNonPermitted })}
				/>
			</Stack>
		</Stack>
	);
}
