/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Spinner,
	Stack,
	Text,
	Toggle,
} from 'components';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import { ReactElement, useMemo, useState } from 'react';

import {
	FilterDropDown,
	FilterDropDownListWrapper,
} from '../../../../components/Select/Dropdown/presets/FilterDropDown';
import { useAsyncState } from '../../../../utils/hooks/useAsyncState';
import { usePromise } from '../../../../utils/hooks/usePromise';
import { IconDuplicate } from '../../../../components/icons';
import { PageParams } from '../../../../utils/hooks/usePage';
import { useTenant } from '../../../../tenancy/useTenant';
import { Services } from '../../../../services/services';
import { Separator } from '../../../../hocs/Separator';
import Teams from '../../../experiments/runs/Teams';

export default function IcalLinkGenerator({ onClose }: { onClose?: () => void }): ReactElement {
	const tenant = useTenant();
	const [icalAccessUrl] = useAsyncState(() => Services.settingsApi.getIcalAccessUrl());
	const teamsResult = usePromise(() => Services.teams.fetchTeams(new PageParams(0, 1_000)), []);
	const teams = teamsResult.value?.content || [];
	const [includeExecutions, setIncludeExecutions] = useState(true);
	const [includeSchedules, setIncludeSchedules] = useState(true);
	const [teamIds, setTeamIds] = useState<string[]>([]);

	const url = useMemo(() => {
		if (!includeExecutions && !includeSchedules) {
			return 'You need to select executions, schedules or both';
		}

		const params = new URLSearchParams();
		params.append('tenantKey', tenant.key);
		if (includeExecutions) {
			params.append('includeExecutions', 'true');
		}
		if (includeSchedules) {
			params.append('includeSchedules', 'true');
		}
		if (teamIds.length > 0) {
			teamIds.forEach((teamId) => {
				const team = teams.find((team) => team.id === teamId);
				if (team) {
					params.append('team', team.key);
				}
			});
		}
		return `${icalAccessUrl.value}?${params.toString()}`;
	}, [icalAccessUrl.value, includeSchedules, includeExecutions, teamIds]);

	return (
		<ModalV2 withFooter>
			<ModalHeaderV2 title={'iCal Link Generator'} onClose={onClose} />
			<ModalContentV2>
				{icalAccessUrl.loading ? (
					<Stack justifyContent="center" alignItems="center">
						<Spinner variant="xLarge" color="neutral300" />
					</Stack>
				) : (
					<Stack>
						<Text variant="smallStrong" color="neutral800">
							Include past experiment executions
						</Text>
						<Toggle checked={includeExecutions} onChange={(e) => setIncludeExecutions(e.target.checked)} />
						<Text variant="smallStrong" color="neutral800">
							Include future experiment schedules
						</Text>
						<Toggle checked={includeSchedules} onChange={(e) => setIncludeSchedules(e.target.checked)} />
						<Text variant="smallStrong" color="neutral800">
							Team Filter (optional - if you don&apos;t select teams, all teams will be included)
						</Text>
						<FilterDropDown
							placeholder="Type to search"
							loading={teamsResult.loading}
							name="Team"
							label={teamIds
								.map((teamId) => teams.find((team) => team.id === teamId))
								.filter((team) => team !== undefined)
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((team) => team.name)
								.concat(teamIds.filter((teamId) => teams.find((team) => team.id === teamId) === undefined))
								.join(', ')}
							clearAll={() => setTeamIds([])}
						>
							{({ value, width }) => (
								<FilterDropDownListWrapper width={width}>
									<Teams
										selectedTeamIds={teamIds}
										teams={teams}
										value={value}
										width={width}
										toggleId={(_teamId) => {
											setTeamIds(
												teamIds.indexOf(_teamId) !== -1
													? teamIds.filter((id) => id !== _teamId)
													: [...teamIds, _teamId],
											);
										}}
										selectIds={(_teamIds) => {
											setTeamIds(Array.from(new Set([...teamIds, ..._teamIds])));
										}}
										deselectIds={(_teamIds) => {
											setTeamIds(teamIds.filter((id) => !_teamIds.includes(id)));
										}}
									/>
								</FilterDropDownListWrapper>
							)}
						</FilterDropDown>
						<Separator />
						<Text variant="medium" color="neutral600" sx={{ lineBreak: 'anywhere' }}>
							{url}
						</Text>
					</Stack>
				)}
			</ModalContentV2>
			<ModalFooterV2>
				<>
					<ReactCopyToClipboard text={url} onCopy={onClose}>
						<Button width="96px" variant={'primary'} sx={{ width: 250 }} disabled={!url.startsWith('http')}>
							<IconDuplicate mr="xSmall" />
							Copy to Clipboard & Close
						</Button>
					</ReactCopyToClipboard>
				</>
			</ModalFooterV2>
		</ModalV2>
	);
}
