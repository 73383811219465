/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconChevronDown, IconChevronUp, IconEnvironment, IconEnvironmentGlobal } from 'components/icons';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { Container, Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface EnvironmentDropdownProps {
	environments: ReturnType<typeof useEnvironments>;
	selectEnvironmentId: (id: string) => void;
	selectedEnvironmentId: string;
}
export default function EnvironmentDropdown({
	selectedEnvironmentId,
	environments,
	selectEnvironmentId,
}: EnvironmentDropdownProps): ReactElement {
	const selectedEnvironment =
		environments.environmentsOfCurrentTeam.find((env) => env.id === selectedEnvironmentId) ||
		environments.globalEnvironment;

	return (
		<Dropdown<string>
			width={400}
			value={selectedEnvironmentId}
			onValueChanged={selectEnvironmentId}
			renderComponent={({ setShowMenu, showMenu, ref }) => {
				const IconComponent = showMenu ? IconChevronUp : IconChevronDown;

				return (
					<Container
						ref={ref}
						sx={{
							position: 'relative',
							width: '400px',
							borderRadius: '4px',
							bg: 'neutral000',
							border: '1px solid ' + (showMenu ? theme.colors.slate : theme.colors.neutral300),
							outline: '1px solid ' + (showMenu ? theme.colors.slate : 'transparent'),
							cursor: 'pointer',
						}}
						onClick={() => setShowMenu(!showMenu)}
					>
						{selectedEnvironment ? (
							<Environment title={selectedEnvironment.name} isGlobal={selectedEnvironment.global} isSelected />
						) : (
							<EnvironmentPlaceholder />
						)}
						<IconComponent
							variant="medium"
							sx={{
								position: 'absolute',
								top: '18px',
								right: '18px',
								color: 'neutral800',
							}}
						/>
					</Container>
				);
			}}
		>
			{({ width, value, selectItem }) => (
				<DropdownContentFrame maxWidth={width} maxHeight="420px">
					<div
						style={{
							width,
							maxWidth: width,
							border: '1px solid ' + theme.colors.neutral200,
						}}
					>
						{environments.environmentsOfCurrentTeam.map((env, i) => (
							<Container
								key={env.id}
								sx={{
									borderBottom:
										i === environments.environmentsOfCurrentTeam.length - 1
											? 'none'
											: '1px solid ' + theme.colors.neutral200,
									cursor: 'pointer',
									':hover': {
										bg: theme.colors.purple100,
									},
								}}
								onClick={() => selectItem(env.id)}
							>
								<Environment title={env.name} isGlobal={env.global} isSelected={env.id === value} />
							</Container>
						))}
					</div>
				</DropdownContentFrame>
			)}
		</Dropdown>
	);
}

interface EnvironmentProps {
	isSelected?: boolean;
	isGlobal?: boolean;
	title: string;
}

function Environment({ title, isSelected = false, isGlobal = false }: EnvironmentProps): ReactElement {
	const IconComponent = isGlobal ? IconEnvironmentGlobal : IconEnvironment;
	return (
		<Stack
			direction="horizontal"
			size="xSmall"
			sx={{
				alignItems: 'center',
				px: 'small',
				py: 'xSmall',

				borderRadius: '4px',
			}}
		>
			<RadioIcon isSelected={isSelected} />
			<div
				style={{
					height: '40px',
					width: '1px',
					backgroundColor: theme.colors.neutral200,
				}}
			/>
			<IconComponent
				sx={{
					minWidth: '20px',
					minHeight: '20px',
					maxWidth: '20px',
					maxHeight: '20px',
				}}
				color="neutral800"
			/>
			<Text variant="smallStrong" color="neutral800">
				{title}
			</Text>
		</Stack>
	);
}

function EnvironmentPlaceholder(): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="xSmall"
			sx={{
				alignItems: 'center',
				px: 'small',
				py: 'xSmall',

				height: '56px',
				borderRadius: '4px',
			}}
		>
			<Text variant="smallStrong" color="neutral800">
				Select Environment
			</Text>
		</Stack>
	);
}

function RadioIcon({ isSelected }: { isSelected: boolean }): ReactElement {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				width: '14px',
				height: '14px',

				borderRadius: '50%',
				border: '2px solid ' + (isSelected ? theme.colors.slate : theme.colors.neutral400),
			}}
		>
			{isSelected && (
				<div
					style={{
						width: '8px',
						height: '8px',
						borderRadius: '50%',
						backgroundColor: theme.colors.slate,
					}}
				/>
			)}
		</div>
	);
}
