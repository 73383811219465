/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	ActionVO,
	BaseExperimentStepExecutionVO,
	ExperimentExecutionVO,
	ExperimentStepExecutionActionVO,
	PredefinedWidgetVO,
	WidgetVO,
} from 'ui-api';
import PredefinedWidget, {
	PredefinedWidgetType,
} from 'pages/experiments/components/widgets/PredefinedWidget/PredefinedWidget';
import LogCard, { LogCardType } from 'pages/experiments/components/widgets/LogCard/LogCard';
import { usePromise } from 'utils/hooks/usePromise';
import { ReactElement, useMemo } from 'react';
import { Services } from 'services/services';
import { getHash } from 'utils/hash';
import { uniqBy } from 'lodash';

import StateOverTimeCard, { StateOverTimeCardType } from './StateOverTimeCard/StateOverTimeCard';
import { ExperimentRunLogsAndMetricsProps } from '../experimentExecutionLogsAndMetrics';
import MarkdownWidget, { MarkdownWidgetType } from './MarkdownWidget/MarkdownWidget';
import { Widget } from './types';

const supportedWidgetComponents: Record<string, Widget> = {
	[StateOverTimeCardType]: StateOverTimeCard,
	[PredefinedWidgetType]: PredefinedWidget,
	[MarkdownWidgetType]: MarkdownWidget,
	[LogCardType]: LogCard,
};

const predefinedWidgetsWhichAreAllowedToBeShownMultipleTimes = ['com.steadybit.widget.predefined.HttpCheck'];

type WidgetsProps = Pick<
	Omit<ExperimentRunLogsAndMetricsProps, 'selectedStepId' | 'setSelectedStepId'>,
	'duration' | 'start' | 'position' | 'experimentExecution' | 'onPositionSelect'
>;

type ActionWidgets = {
	stepAction: ExperimentStepExecutionActionVO;
	widget: WidgetVO;
};

export default function Widgets({
	experimentExecution,
	duration,
	start,
	position,
	onPositionSelect,
}: WidgetsProps): ReactElement | null {
	const actions = usePromise(() => Services.actions.fetchActions(), []);

	const widgets = useMemo(() => {
		if (actions.value) {
			return findAllRelevantWidgets(experimentExecution, actions.value);
		}
		return [];
	}, [experimentExecution, actions.value]);

	const actionWidgetsForMultipleWidgets = useMemo(() => {
		if (actions.value) {
			return findAllRelevantActions(
				experimentExecution,
				actions.value,
				predefinedWidgetsWhichAreAllowedToBeShownMultipleTimes,
			);
		}
		return [];
	}, [experimentExecution, actions.value]);

	if (!actions.value) {
		return null;
	}

	return (
		<>
			{actionWidgetsForMultipleWidgets.map((actionWidgets) => {
				const Component = supportedWidgetComponents[actionWidgets.widget.type];
				if (Component == null) {
					return null;
				}
				return (
					<>
						<Component
							key={actionWidgets.stepAction.id}
							widget={actionWidgets.widget}
							duration={duration}
							start={start}
							position={position}
							experimentExecution={experimentExecution}
							onPositionSelect={onPositionSelect}
							stepAction={actionWidgets.stepAction}
						/>
					</>
				);
			})}
			{widgets
				.sort((a, b) => a.type.localeCompare(b.type))
				.map((widget) => {
					const Component = supportedWidgetComponents[widget.type];
					if (Component == null) {
						return null;
					}

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const title = (widget as any)['title'] || '';

					return (
						<Component
							experimentExecution={experimentExecution}
							position={position}
							duration={duration}
							key={widget.type + title}
							widget={widget}
							start={start}
							onPositionSelect={onPositionSelect}
						/>
					);
				})
				.filter(Boolean)}
		</>
	);
}

function findAllRelevantWidgets(experimentExecution: ExperimentExecutionVO, actions: ActionVO[]): WidgetVO[] {
	const result: WidgetVO[] = [];

	for (const lane of experimentExecution.lanes) {
		for (const step of lane.steps) {
			if (isExperimentStepExecutionActionVO(step)) {
				const action = actions.find((a) => a.id === step.actionId);
				if (action) {
					for (const widget of action.widgets) {
						if (widget.type === PredefinedWidgetType) {
							const widgetAsPredefinedWidget = widget as PredefinedWidgetVO;
							if (
								!predefinedWidgetsWhichAreAllowedToBeShownMultipleTimes.includes(
									widgetAsPredefinedWidget.predefinedWidgetId,
								)
							) {
								result.push(widget);
							}
						} else {
							result.push(widget);
						}
					}
				}
			}
		}
	}

	return uniqBy(result, getHash);
}

function findAllRelevantActions(
	experimentExecution: ExperimentExecutionVO,
	actions: ActionVO[],
	predefinedWidgetsWhichAreAllowedToBeShownMultipleTimes: string[],
): ActionWidgets[] {
	const result: ActionWidgets[] = [];

	for (const lane of experimentExecution.lanes) {
		for (const step of lane.steps) {
			if (isExperimentStepExecutionActionVO(step)) {
				const action = actions.find((a) => a.id === step.actionId);
				if (action && action.widgets.length > 0) {
					for (const widget of action.widgets) {
						if (widget.type === PredefinedWidgetType) {
							const widgetAsPredefinedWidget = widget as PredefinedWidgetVO;
							if (
								predefinedWidgetsWhichAreAllowedToBeShownMultipleTimes.includes(
									widgetAsPredefinedWidget.predefinedWidgetId,
								)
							) {
								result.push({ stepAction: step, widget: widget });
							}
						}
					}
				}
			}
		}
	}
	return uniqBy(result, (actionWidgets) => {
		if (actionWidgets.widget.type === PredefinedWidgetType && actionWidgets.widget.type === PredefinedWidgetType) {
			const widgetAsPredefinedWidget = actionWidgets.widget as PredefinedWidgetVO;
			if (widgetAsPredefinedWidget.predefinedWidgetId === 'com.steadybit.widget.predefined.HttpCheck') {
				return getHash(actionWidgets.stepAction.parameters['url']);
			}
		}
		return getHash(actionWidgets.widget);
	});
}

function isExperimentStepExecutionActionVO(
	step: BaseExperimentStepExecutionVO,
): step is ExperimentStepExecutionActionVO {
	return step.type === 'action';
}
