/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import useObservable from 'react-use/lib/useObservable';
import { Container, StyleProp } from 'components';
import React, { ReactNode, useMemo } from 'react';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import { ReplaySubject } from 'rxjs';
import { debounce } from 'lodash';
import ReactDOM from 'react-dom';

interface Binding {
	target: 'reference';
	offset: number;
}

export type TooltipProps = {
	content: React.ReactNode | (() => React.ReactNode);
	placement?: PopperJS.Placement;
	onlyShowOnEllipsis?: boolean;
	color?: 'dark' | 'light';
	bindWidth?: Binding;
	children: ReactNode;
	disabled?: boolean;
};

const assertValidChild = (children?: React.ReactNode): React.ReactElement => {
	const child = React.Children.only(children);
	if (React.isValidElement(child)) {
		return child;
	}
	throw new Error('The Tooltip only accepts a single valid element child.');
};

const isGlobalVisible$ = new ReplaySubject(1);
const setIsGlobalVisible = debounce((b) => isGlobalVisible$.next(b), 400);

const STYLE_DARK = {
	backgroundColor: 'neutral800',
	color: 'neutral000',
};
const STYLE_LIGHT = {
	backgroundColor: 'neutral000',
	color: 'neutral800',
	border: '1px solid',
	borderColor: 'neutral300',
};

export const Tooltip: React.FC<TooltipProps> = ({
	onlyShowOnEllipsis,
	placement = 'top',
	color = 'dark',
	bindWidth,
	children,
	disabled,
	content,
}) => {
	const [referenceEl, setReferenceEl] = React.useState<HTMLElement | null>(null);
	const [popperEl, setPopperEl] = React.useState<HTMLElement | null>(null);
	const isGlobalVisible = useObservable(isGlobalVisible$);
	const [isTriggered, setIsTriggered] = React.useState(false);

	const { styles, attributes } = usePopper(referenceEl, popperEl, {
		placement,
		modifiers: [
			{
				name: 'arrow',
				options: {
					padding: 0,
				},
			},
			{
				name: 'preventOverflow',
				options: {
					rootBoundary: 'viewport',
				},
			},
			{
				name: 'offset',
				options: {
					offset: [bindWidth ? bindWidth.offset / 2 : 0, 2],
				},
			},
		],
	});

	const tooltipContent = React.useMemo(
		() => (isTriggered && isGlobalVisible ? (typeof content === 'function' ? content() : content) : null),
		[isTriggered, isGlobalVisible, content],
	);

	const sxColor = (): StyleProp => {
		switch (color) {
			case 'dark':
				return STYLE_DARK;
			case 'light':
				return STYLE_LIGHT;
		}
	};

	const child = assertValidChild(children);
	const enhancedChild = useMemo(() => {
		//If the child is disabled, wrap it with a div and set pointer-events: none for the nested element
		//This is due to disabled inputs/buttons don't emitting mouseleave correctly.
		//React fixed this by also not firing mouseenter events. See https://github.com/facebook/react/issues/4251
		const wrappedChild = child.props.disabled ? <span role={'presentation'}>{child}</span> : child;

		return React.cloneElement(wrappedChild, {
			...wrappedChild.props,
			ref: setReferenceEl,
			onMouseEnter: (e: React.MouseEvent<HTMLElement>) => {
				if (
					!disabled &&
					(!onlyShowOnEllipsis || (e.currentTarget.offsetWidth ?? 0) < (e.currentTarget.scrollWidth ?? 0))
				) {
					setIsTriggered(true);
					setIsGlobalVisible(true);
				}
			},
			onMouseLeave: () => {
				setIsTriggered(false);
				setIsGlobalVisible(false);
			},
		});
	}, [child, setReferenceEl, onlyShowOnEllipsis]);

	return (
		<>
			{enhancedChild}
			{isTriggered && isGlobalVisible && tooltipContent
				? ReactDOM.createPortal(
						<Container
							ref={setPopperEl}
							style={styles.popper}
							{...attributes.popper}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								variant: 'text.smallStrong',
								zIndex: 200,
								overflow: 'hidden',

								...(bindWidth
									? {
											width: (referenceEl?.offsetWidth || 0) + bindWidth.offset,
											maxWidth: (referenceEl?.offsetWidth || 0) + bindWidth.offset,
										}
									: {}),
							}}
						>
							<Container
								sx={{
									px: 'xSmall',
									py: 'xxSmall',
									...sxColor(),
									borderRadius: '4px',
									pointerEvents: 'none',
									width: 'fit-content',
									wordBreak: 'break-word',
								}}
							>
								{tooltipContent}
							</Container>
						</Container>,
						document.body,
					)
				: null}
		</>
	);
};
