/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ExperimentPreview from 'pages/templates/components/ExperimentPreview';
import { useStableErrorMap } from 'pages/experimentsV2/useFieldErrors';
import { TemplateError } from 'pages/templates/TemplateEditor/types';
import { ErrorMessage, Flex } from '@steadybit/ui-components-lib';
import { DataStreamResult } from 'utils/hooks/stream/result';
import Markdown from 'components/Markdown/Markdown';
import { useField, useFormikContext } from 'formik';
import { ActionVO, OccuranceVO } from 'ui-api';
import { Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import {
	isOccuranceActionPredicateVO,
	isOccuranceStepLabelVO,
	isOccuranceStepParameterVO,
	isOccuranceTemplateFieldVO,
} from '../types';
import { UseTemplateFormData } from '../UseTemplateForm';
import OccuranceValue from './OccuranceValue';

interface TemplatePlaceholderProps {
	actionsResult: DataStreamResult<ActionVO[]>;
	placeholder: Placeholder;
}

export interface Placeholder {
	description: string;
	value: string;
	name: string;
	key: string;
}

export default function TemplatePlaceholder({ placeholder, actionsResult }: TemplatePlaceholderProps): ReactElement {
	const { values, setFieldValue } = useFormikContext<UseTemplateFormData>();
	const selectedPlaceholder = values.placeholdersMap.get(placeholder.key);

	const selectedStepIds: Set<string> = new Set();
	if (selectedPlaceholder) {
		collectStepIds(selectedPlaceholder, selectedStepIds);
	}

	const stepIdToError = useStableErrorMap();

	const appearsInHypothesis = (selectedPlaceholder || []).some(
		(occurance) => isOccuranceTemplateFieldVO(occurance) && occurance.templateField === 'HYPOTHESIS',
	);

	const appearsInExperimentName = (selectedPlaceholder || []).some(
		(occurance) => isOccuranceTemplateFieldVO(occurance) && occurance.templateField === 'EXPERIMENT_NAME',
	);

	return (
		<Stack size="large" pb="xLarge">
			<Stack size="xSmall">
				<Markdown content={placeholder.description} />
				<OccuranceValue
					occurances={selectedPlaceholder || []}
					value={placeholder.value}
					setValue={(_value) => {
						const clone = new Map(values.placeholderValuesMap);
						clone.set(placeholder.key, _value);
						setFieldValue('placeholderValuesMap', clone);
					}}
				/>
			</Stack>
			{appearsInExperimentName && (
				<ExperimentTextfieldUsage
					placeholderName={placeholder.name}
					usageName="Experiment name"
					fieldName="experimentName"
					errorFieldName="name"
					maxChars={255}
				/>
			)}
			{appearsInHypothesis && (
				<ExperimentTextfieldUsage
					placeholderName={placeholder.name}
					errorFieldName="hypothesis"
					fieldName="hypothesis"
					usageName="hypothesis"
					maxChars={20_000}
				/>
			)}
			{selectedStepIds.size > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral800">
						<Text variant="smallStrong" color="neutral800" as={'span'}>
							{placeholder.name}
						</Text>
						&nbsp;is used in the following steps:
					</Text>

					<ExperimentPreview
						key={placeholder.key}
						actions={actionsResult.value || []}
						selectedStepIds={selectedStepIds}
						stepIdToError={stepIdToError}
						lanes={values.lanes}
					/>
				</Stack>
			)}
		</Stack>
	);
}

function collectStepIds(occurances: OccuranceVO[], ids: Set<string>): void {
	occurances.forEach((occurance) => {
		if (isOccuranceActionPredicateVO(occurance)) {
			ids.add(occurance.stepId);
		} else if (isOccuranceStepLabelVO(occurance)) {
			ids.add(occurance.stepId);
		} else if (isOccuranceStepParameterVO(occurance)) {
			ids.add(occurance.stepId);
		}
	});
}

interface ExperimentTextfieldUsageProps {
	placeholderName: string;
	errorFieldName: string;
	fieldName: string;
	usageName: string;
	maxChars: number;
}

function ExperimentTextfieldUsage({
	placeholderName,
	errorFieldName,
	fieldName,
	usageName,
	maxChars,
}: ExperimentTextfieldUsageProps): ReactElement {
	const [{ value }] = useField<string>(fieldName);
	const [, meta] = useField<string>(errorFieldName);

	const error = meta.error as TemplateError | undefined;

	return (
		<Flex spacing="xSmall">
			<Flex direction="horizontal" wrap spacing="xxSmall">
				<Text as="span" variant="smallStrong" sx={{ minWidth: 'min-content' }}>
					{placeholderName}
				</Text>
				<Text as="span" variant="smallMedium" sx={{ minWidth: 'min-content' }}>
					is used in the {usageName}:
				</Text>
			</Flex>

			<Flex
				direction="horizontal"
				wrap
				style={{
					background: theme.colors.neutral150,
					gap: '8px',
					px: 'small',
					py: 'xSmall',
					borderRadius: 'xxSmall',
					width: 'calc(100% - 40px)',
					overflow: 'auto',
					minHeight: '20px',
				}}
			>
				<Text
					as="span"
					variant="small"
					style={{
						whiteSpace: 'pre-wrap',
						wordWrap: 'break-word',
					}}
				>
					{value.substring(0, maxChars)}
				</Text>
			</Flex>

			{error && value.length >= maxChars && (
				<ErrorMessage
					type="small"
					level="warning"
					withIcon
				>{`The ${usageName} is too long (${value.length}/${maxChars}) and will be truncated.`}</ErrorMessage>
			)}
			{error && value.length === 0 && (
				<ErrorMessage type="small" level={error.level} withIcon>
					{error.message}
				</ErrorMessage>
			)}
		</Flex>
	);
}
