/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Checkbox,
	Container,
	Pagination,
	Pill,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableErrorRow,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	Tooltip,
} from 'components';
import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { IconCheck, IconInformation, IconWarningOpen } from 'components/icons';
import { HubVO, TemplateFromHubRequestVO, TemplateSummaryVO } from 'ui-api';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import SearchBar from 'pages/components/SearchBar/SearchBar';
import Tags from 'pages/templates/components/TemplateTags';
import { localeCompareIgnoreCase } from 'utils/string';
import { Flex } from '@steadybit/ui-components-lib';
import { usePromise } from 'utils/hooks/usePromise';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';

import HubSynchronization, { WaitingContent } from './HubSynchronization';
import { formatDateWithTime } from '../../../utils/dateFns';
import { HubLine } from './ChooseHub';

interface ChooseTemplatesFromHubProps {
	selectedTemplates: TemplateSummaryVO[];
	hub: HubVO;
	setSelectedTemplates: (templates: TemplateSummaryVO[]) => void;
}

export default function ChooseTemplatesFromHub({
	selectedTemplates,
	hub,
	setSelectedTemplates,
}: ChooseTemplatesFromHubProps): ReactElement {
	return (
		<HubSynchronization hubId={hub.id}>
			{([syncState, refresh]) => {
				if (!syncState.completed) {
					return (
						<Stack size="medium" my="small">
							<HubLine hub={hub} first isSelected />
							<WaitingContent />
						</Stack>
					);
				}
				return (
					<Stack size="medium" my="small">
						<HubLine hub={hub} first isSelected syncError={syncState.error} />
						<TemplatesTable
							selectedTemplates={selectedTemplates}
							syncError={syncState.error}
							pathname="/<new>"
							hubId={hub.id}
							setSelectedTemplates={setSelectedTemplates}
							onRefresh={refresh}
						/>
					</Stack>
				);
			}}
		</HubSynchronization>
	);
}

interface TemplatesTableProps extends Omit<ChooseTemplatesFromHubProps, 'hub'> {
	syncError?: string;
	pathname: string;
	hubId: string;
	onRefresh: () => void;
}

export function TemplatesTable({
	selectedTemplates,
	syncError,
	pathname,
	hubId,
	setSelectedTemplates,
	onRefresh,
}: TemplatesTableProps): ReactElement {
	const [{ tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam }] = useState(() =>
		createFilterParams(pathname),
	);
	const [{ targetTypes, actions, tags, freeTextPhrases }] = useUrlState<UrlState>([
		freeTextPhrasesParam,
		targetTypesParam,
		actionsParam,
		tagsParam,
	]);

	const templatesResult = usePromise(() => {
		const body: TemplateFromHubRequestVO = {
			hubId,
			freeTextPhrases,
			targetTypes,
			actions,
			tags,
		};
		return Services.templatesApi.getTemplatesFromHub(body);
	}, [
		createStableId(freeTextPhrases),
		createStableId(targetTypes),
		createStableId(actions),
		createStableId(tags),
		hubId,
	]);

	const templates = templatesResult.value?.content || [];

	const targetDefinitionsResult = useTargetDefinitions();
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const searchObjectsResult = usePromise(() => Services.templatesApi.getSearchObjectsFromHub(hubId), [hubId]);

	if (syncError) {
		return <SyncErrorContent onRefresh={onRefresh} />;
	}

	if (templatesResult.error && templatesResult.error.statusCode === 404) {
		return (
			<TemplatesTableWrapper>
				<TableRow>
					<TableDataCell colSpan={3}>
						<Text muted>No templates found.</Text>
					</TableDataCell>
				</TableRow>
			</TemplatesTableWrapper>
		);
	}

	if (templatesResult.error) {
		return (
			<TemplatesTableWrapper>
				<TableErrorRow error="Error loading templates" />
			</TemplatesTableWrapper>
		);
	}

	if (templatesResult.loading || !templatesResult.value) {
		return (
			<TemplatesTableWrapper>
				<TableLoadingRow numColumns={3} />
				<TableLoadingRow numColumns={3} />
				<TableLoadingRow numColumns={3} />
			</TemplatesTableWrapper>
		);
	}

	const isSearchDefined = freeTextPhrases.length > 0 || targetTypes.length > 0 || actions.length > 0 || tags.length > 0;
	if (templates.length === 0) {
		return (
			<>
				{isSearchDefined && (
					<SearchBar
						targetDefinitionsResult={targetDefinitionsResult}
						cypressTag="template-search-input"
						actionsResult={actionsResult}
						searchObjectsResult={searchObjectsResult}
						pathname={pathname}
						mode="templateList"
					/>
				)}
				<TemplatesTableWrapper>
					<TableRow>
						<TableDataCell colSpan={3}>
							<Text muted>{isSearchDefined ? 'No templates matched your query.' : 'No templates found.'}</Text>
						</TableDataCell>
					</TableRow>
				</TemplatesTableWrapper>
			</>
		);
	}

	return (
		<>
			<SearchBar
				targetDefinitionsResult={targetDefinitionsResult}
				cypressTag="template-search-input"
				actionsResult={actionsResult}
				searchObjectsResult={searchObjectsResult}
				pathname={pathname}
				mode="templateList"
			/>

			<PaginatedTemplatesTable
				selectedTemplates={selectedTemplates}
				templates={templates}
				setSelectedTemplates={setSelectedTemplates}
			/>
		</>
	);
}

interface TemplatesTableWrapperProps {
	allSelected?: boolean;
	children: ReactNode;
	selectAll?: (all?: boolean) => void;
}

function TemplatesTableWrapper({ allSelected, children, selectAll }: TemplatesTableWrapperProps): ReactElement {
	return (
		<Table width="100%">
			<TableHead>
				<TableRow>
					<TableHeadCell width="110px">
						{selectAll && (
							<Stack direction="horizontal" size="xSmall" alignItems="center">
								<Checkbox checked={allSelected} onChange={() => selectAll(!allSelected)} />
								<Text variant="tableHeader">{allSelected ? 'Deselect all' : 'Select all'}</Text>
							</Stack>
						)}
					</TableHeadCell>
					<TableHeadCell>Name</TableHeadCell>
					<TableHeadCell>Tags</TableHeadCell>
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
}

interface PaginatedTemplatesTable {
	selectedTemplates: TemplateSummaryVO[];
	templates: TemplateSummaryVO[];
	setSelectedTemplates: (templates: TemplateSummaryVO[]) => void;
}

function PaginatedTemplatesTable({
	selectedTemplates,
	templates,
	setSelectedTemplates,
}: PaginatedTemplatesTable): ReactElement {
	const pageSize = 10;
	const [page, setPage] = useState(0);

	useEffect(() => {
		setPage(0);
	}, [templates.length]);

	const totalElements = templates.length;
	const totalPages = Math.ceil(totalElements / pageSize);
	const templatesPage = templates.slice(page * pageSize, (page + 1) * pageSize);

	useEffect(() => {
		setPage(0);
		setSelectedTemplates([]);
	}, [totalElements]);

	return (
		<Stack>
			<TemplatesTableWrapper
				selectAll={templates.length > 0 ? (all) => setSelectedTemplates(all ? templates : []) : undefined}
				allSelected={selectedTemplates.length === templates.length}
			>
				{templatesPage.length === 0 && (
					<TableRow>
						<TableDataCell colSpan={3}>
							<Text muted>No templates found.</Text>
						</TableDataCell>
					</TableRow>
				)}

				{templatesPage.map((template) => {
					const isSelected = selectedTemplates.some(
						(selectedTemplate) => selectedTemplate.templateTitle === template.templateTitle,
					);
					return (
						<TableRow
							key={template.templateTitle}
							hoverable={true}
							height={54}
							backgroundColor={isSelected ? 'neutral100' : 'neutral00'}
							onClick={() => {
								if (isSelected) {
									setSelectedTemplates(selectedTemplates.filter((t) => t.templateTitle !== template.templateTitle));
								} else {
									setSelectedTemplates([...selectedTemplates, template]);
								}
							}}
						>
							<TableDataCell>
								<Checkbox checked={isSelected} onChange={() => {}} />
								{template.imported && (
									<Tooltip
										content={
											<Container sx={{ width: 330 }}>
												This template is already available on the platform, but you can still re-import it if needed.
											</Container>
										}
									>
										<Container>
											<Pill backgroundColor="success050" color="successDark" sx={{ ml: 'xSmall' }}>
												<IconCheck mr="xxSmall" /> Imported
											</Pill>
										</Container>
									</Tooltip>
								)}
								{template.imported !== template.edited && (
									<Tooltip
										content={
											<Container sx={{ width: 330 }}>
												This template has been modified on {formatDateWithTime(new Date(template.edited))} since it was
												originally imported.
											</Container>
										}
									>
										<Container sx={{ ml: 'xSmall' }}>
											<IconInformation />
										</Container>
									</Tooltip>
								)}
							</TableDataCell>
							<TableDataCell>
								<Tooltip content={template.templateTitle} onlyShowOnEllipsis>
									<Text
										variant="medium"
										as="span"
										sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											'&:hover': {
												textDecoration: 'underline',
												cursor: 'pointer',
												color: 'slate',
											},
										}}
									>
										{template.templateTitle}
									</Text>
								</Tooltip>
							</TableDataCell>
							<TableDataCell sx={{ py: 'xxSmall' }}>
								<Tags tags={template.tags} />
							</TableDataCell>
						</TableRow>
					);
				})}
			</TemplatesTableWrapper>
			<Pagination activePage={page} totalPages={totalPages} onClick={setPage} />
		</Stack>
	);
}

interface SyncErrorContentProps {
	onRefresh: () => void;
}

function SyncErrorContent({ onRefresh }: SyncErrorContentProps): ReactElement {
	return (
		<Flex
			align="center"
			spacing="xSmall"
			style={{
				py: 'xxLarge',
			}}
		>
			<IconWarningOpen variant="large" color="slate" />
			<Text variant="mediumStrong" color="slate">
				Something went wrong
			</Text>

			<Text as="span" variant="small" color="neutral700" textAlign="center" maxWidth="450px">
				We encountered an issue retrieving this hub data. Please
				<Text
					as="span"
					variant="smallStrong"
					color="slate"
					onClick={onRefresh}
					sx={{
						marginLeft: 'xxSmall',
						cursor: 'pointer',
						textDecoration: 'underline',
					}}
				>
					try again
				</Text>{' '}
				or edit the hub&apos;s configuration.
			</Text>
		</Flex>
	);
}

function createStableId(strings: string[] = []): string {
	return strings.slice().sort(localeCompareIgnoreCase).join(',');
}
